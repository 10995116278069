<template>
    <!-- 文章标签列表页面 -->
    <div>
        <div class="articleTagNavi">
            <!-- 面包屑导航 -->
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/welcome' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>文章管理</el-breadcrumb-item>
                <el-breadcrumb-item>标签</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 卡片视图区域  -->
        <el-card class="box-card">
            <!-- gutter设置el-col之间的间距 -->
            <el-row :gutter="20">
                <!-- 添加按钮 -->
                <el-col :span="4">
                    <el-button type="primary" @click='addArticleTagDialogVisible = true'>
                        新建标签
                    </el-button>
                </el-col>
            </el-row>

            <!-- 标签列表  ：data绑定数据源，prop指定参数对应的值， border边框，stripe隔行变色-->
            <el-table :data="articleTagList" border stripe style="width: 100%"
                :header-cell-style="{'text-align':'center'}">
                <el-table-column type="index" label="序号" width="80" align="center">
                </el-table-column>
                <el-table-column prop="tagName" label="标签名称" width="150" align="center">
                </el-table-column>
                <el-table-column prop="tagId" label="标签Id" width="80" align="center">
                </el-table-column>
                <el-table-column prop="articleNum" label="文章数量" width="100" align="center">
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" width="200" align="center">
                </el-table-column>
                <el-table-column prop="updatedTime" label="修改时间" width="200" align="center">
                </el-table-column>

                <el-table-column label="操作">
                    <!-- 作用域插槽的使用 -->
                    <template slot-scope="scope">
                        <!-- enterablez 鼠标是否可进入到 tooltip 中 自动隐藏-->
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <!-- 编辑按钮 -->
                            <el-button type="primary" icon="el-icon-edit" @click="showEditDialog(scope.row)">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <!-- 删除按钮 -->
                            <el-button type="danger" icon="el-icon-delete" @click="removeTagInfor(scope.row.tagId)">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 页码 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="tagInfor.page" :page-size="articlePageSize" layout="total, prev, pager, next, jumper"
                :total="articleTotal" class="articlePage-list">
            </el-pagination>

            <!-- 添加文章标签的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="添加标签" :visible.sync="addArticleTagDialogVisible" width="50%"
                @close="addArticleTagDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <!-- prop验证规则属性 v-model双向绑定数据-->
                    <el-form-item label="标签名称" prop="tagName">
                        <el-input v-model="addForm.tagName"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addArticleTagDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addArticleTag">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 修改文章标签的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="修改标签名称" :visible.sync="editArticleTagDialogVisible" width="50%"
                @close="editArticleTagDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="标签名称" prop="tagName">
                        <el-input v-model="editForm.tagName"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editArticleTagDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editTagInfor">确 定</el-button>
                </div>
            </el-dialog>
        </el-card>

    </div>
</template>



<!-- 行为 -->
<script>
    export default {
        // 数据处理
        data() {
            return {
                //获取文章标签列表的所需要设置的参数
                tagInfor: {
                    page: 1, //文章标签列表页码数，默认每页5条数据
                },
                articleTotal: 0,  //文章标签总数
                articlePageSize: 5, //默认每页5条数据

                //文章标签列表---每页最多5条数据
                articleTagList: [],

                // 控制添加文章标签对话框的显示与隐藏
                addArticleTagDialogVisible: false,

                //添加文章标签的表单数据对象
                addForm: {
                    tagName: '',    //标签名称
                },

                // 添加文章标签表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addFormRules: {
                    tagName: [
                        { required: true, message: '请输入标签名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '标签名称长度在2到15个字符', trigger: 'blur' }
                    ],
                },

                // 控制修改文章标签对话框的显示与隐藏
                editArticleTagDialogVisible: false,

                //获取到修改标签的数据对象
                editForm: {},

                // 修改标签名称表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                editFormRules: {
                    tagName: [
                        { required: true, message: '请输入标签名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '标签名称长度在2到15个字符', trigger: 'blur' }
                    ]
                }
            }
        },

        //创建
        created() {
            this.getArticleTagList();
        },

        // 方法
        methods: {
            // 获取文章标签列表, async代表异步操作
            async getArticleTagList() {
                const { data: res } = await this.$http.get('/article/tag/list', {
                    params: this.tagInfor,
                });
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.articleTagList = res.tagList;
                this.articleTotal = res.totalNumber;
            },

            // 监听pageSize改变事件
            handleSizeChange(newPage) {
                console.log('handleSizeChange');
                this.tagInfor.page = newPage;
                this.getArticleTagList();
            },

            // 监听当前页码改变
            handleCurrentChange(newPage) {
                this.tagInfor.page = newPage;
                this.getArticleTagList();
            },

            // 监听添加标签对话关闭事件
            addArticleTagDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addFormRef !== undefined) {
                    this.addFormRef.resetFields();
                }
            },


            // 点击确定按钮，添加标签信息
            addArticleTag() {
                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    console.log('数据请求');
                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/tag/add', {
                        tagName: this.addForm.tagName,
                        token: window.sessionStorage.getItem('token'),
                    });
                    console.log(res);
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    this.$message.success('新建标签名称成功');

                    // 隐藏添加的对话
                    this.addArticleTagDialogVisible = false;

                    // 重新获取标签列表
                    this.getArticleTagList();
                })
            },

            //点击编辑的修改标签弹窗
            showEditDialog(row) {
                console.log('编辑的修改标签弹窗');
                console.log(JSON.parse(JSON.stringify(row)));
                this.editArticleTagDialogVisible = true;
                this.editForm = JSON.parse(JSON.stringify(row));
            },

            //修改标签弹窗关闭
            editArticleTagDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.editFormRules !== undefined) {
                    this.editFormRules.resetFields();
                }
            },

            //修改标签信息，提交
            editTagInfor() {
                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                this.$refs.editFormRef.validate(async valid => {
                    console.log(valid);
                    if (!valid) {//预校验不通过
                        console.log('预校验不通过');
                        return;
                    }
                    console.log(this.editForm.id);

                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/tag/edit', {
                        tagId: this.editForm.tagId,
                        tagName: this.editForm.tagName,
                        token: window.sessionStorage.getItem('token'),
                    });
                    console.log(res.data);
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    this.$message.success('修改分类成功');

                    // 隐藏修改标签的对话
                    this.editArticleTagDialogVisible = false;

                    //重新获取列表
                    this.getArticleTagList();
                })
            },


            //删除标签信息
            async removeTagInfor(tagId) {
                // 询问是否真的删除当前
                const confirmResult = await this.$confirm('此操作将删除该标签名称, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => {
                    return err;
                });

                // 如果标签名称，确认删除，则返回值为字符串 confirm 
                // 如果标签名称，取消删除，则返回值为字符串 cancel 
                console.log(confirmResult);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除操作');
                }

                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                //预校验通过,
                const { data: res } = await this.$http.post('/article/tag/delete', {
                    tagId: tagId,
                    token: window.sessionStorage.getItem('token'),
                });
                if (res.status >= 400 && res.status <= 410) {//用户权限问题，需要登录
                    this.$router.push('/login');
                    return this.$message.error(res.msg);
                } else {
                    if (res.status != 200) {
                        return this.$message.error(res.msg);
                    }
                }
                this.$message.success('删除标签名称成功');

                //重新获取列表
                this.getArticleTagList();
            }

        },

    }
</script>



<!-- 样式 -->
<style lang="less" scoped>
    /*  使用deep样式穿透 面包屑导航里面字体样式 */
    .articleTagNavi /deep/ .el-breadcrumb {
        font-size: 20px !important;
    }
</style>