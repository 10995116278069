<script>
    // 定义全局变量userName
    var userName = '';     //用户名称
    var userId = 0;        //用户Id
    var isSuperuser = 0;  //默认不是
    var isStaff = 0;      //默认不是

    // 修改全局变量userName的值
    function setUserName(userNameStr){
        this.userName = userNameStr
    }

    export default {
        userName, //用户名称
        userId, //用户id
        isSuperuser, //是否超级管理员
        isStaff,    //代表是否是员工
        // 全局变量的方法
        setUserName,
    }
</script>