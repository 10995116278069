
<template>
    <el-container class="home-container">
        <!-- 页面头部区域 -->
        <el-header>
            <div class="home-container-title">
                <span>欢迎来到个人博客后台管理</span>
            </div>
            <el-button type="info" @click="logOut">退出</el-button>
        </el-header>

        <el-container>
            <!-- 左侧栏 width做动态绑定处理-->
            <el-aside :width="isCollapse ? '64px' : '200px'" class="home-aside">
                <!-- 左侧栏折叠功能 -->
                <div class="toggle-button" @click='toggleCollspace'>|||</div>

                <!-- active-text-color激活文本(选中)的颜色  
                    unique-opened是否保存一个菜单栏   
                    collapse是否折叠左侧栏   
                    collapse-transition折叠动画效果
                    router 开启使用 vue-router 的模式，启用该模式会在激活导航时以 index 作为 path 进行路由跳转
                    default-active  当前激活菜单的 index 进行绑定-->
                <el-menu background-color="#545c64" text-color="#fff" active-text-color="#409EFF" unique-opened 
                :collapse="isCollapse" :collapse-transition="false" router  :default-active="activePath">
                    <!-- 一级菜单栏  强调说明：index里面的值必须是字符串类型，否则会报错警告-->
                    <el-submenu :index="item.id +'' "  v-for="item in menuList" :key="item.id">
                        <!-- 一级菜单模板区域 -->
                        <template slot="title">
                            <!-- 图标 -->
                            <i :class="iconObject[item.id]"></i>
                            <!-- 文本 使用动态绑定{{}}-->
                            <span>{{item.authName}}</span>
                        </template>
 
                        <!-- 二级菜单栏 -->
                        <el-menu-item :index= "'/' + subItem.path"  v-for="subItem in item.children" :key="subItem.id" 
                        @click="saveNaveState('/' + subItem.path)">
                            <template slot="title">
                                <!-- 图标 -->
                                <i class="el-icon-menu"></i>
                                <!-- 文本 -->
                                <span>{{subItem.authName}}</span>
                            </template>
                        </el-menu-item>

                    </el-submenu>
                </el-menu>
            </el-aside>

            <!-- 右侧主体内容 -->
            <el-main>
                <!-- 设置一个路由占位符 -->
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>

</template>




<script src=".../assets/font_images/iconfont.js"></script>
<script >
    export default {
        //数据层
        data() {
            return {
                // 左侧菜单栏数据
                menuList: [],

                //一级菜单栏左侧图标
                iconObject:{
                    1:'iconfont el-icon-third-gongsi',
                    2:'iconfont el-icon-third-gongsi',
                    3:'iconfont el-icon-third-gongsi',
                    4:'iconfont el-icon-third-gongsi'
                },
                
                //是否折叠 
                isCollapse:false,

                //被激活的链接地址，默认为空
                activePath:''
            }
        },

        //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
        created() {
            this.getMenList();
            this.activePath = window.sessionStorage.getItem('activePath');
        },

        //方法
        methods: {
            // 退出登录，消耗token ，并进入到登录界面
            logOut() {
                window.sessionStorage.clear();
                this.$router.push('/login');
            },

            //获取数据---
            getMenList() {
                this.menuList = [
                    {
                        'authName': '文章管理',
                        'id': 1,
                        'order': 1,
                        'path': 'article',
                        'children': [
                        {
                            'authName': '文章',
                            'id': 11,
                            'order': 11,
                            'path': 'articleList',
                        },
                        {
                            'authName': '分类',
                            'id': 12,
                            'order': 12,
                            'path': 'articleType',
                        },
                        {
                            'authName': '标签',
                            'id': 13,
                            'order': 13,
                            'path': 'articleTag',
                        }]
                    },
                    {
                        'authName': '个人账本',
                        'id': 2,
                        'order': 2,
                        'path': 'book',
                        'children': [
                        {
                            'authName': '消费记录',
                            'id': 21,
                            'order': 21,
                            'path': 'bookList',
                        },
                        {
                            'authName': '消费分类',
                            'id': 22,
                            'order': 22,
                            'path': 'bookType',
                        }]
                    },

                    {
                        'authName': '用户管理',
                        'id': 4,
                        'order': 4,
                        'path': 'userManager',
                        'children': [{
                            'authName': '用户',
                            'id': 41,
                            'order': 41,
                            'path': 'userList',
                        }]
                    }
                    
                ];
            },

            //折叠菜单栏功能
            toggleCollspace(){
                this.isCollapse = !this.isCollapse;
            },

            // 保存链接的激活状态
            saveNaveState(activePath){
                console.log('保存链接的激活状态------');
                console.log(activePath);
                window.sessionStorage.setItem('activePath',activePath);
                this.activePath = activePath;
            }
        },

    }
</script>



<!-- scoped表示当前页面有效   lang="less" scoped-->
<style lang="less" scoped>
    .home-container {
        height: 100%;
    }

    .el-header {
        background-color: #118575;
        display: flex;
        /* 二端对齐方式 */
        justify-content: space-between;
        padding-left: 0px;
        /* 退出按钮居中显示 */
        align-items: center;
        /* 字体颜色 */
        color: #ffffff;
        font-size: 22px;
    }

    .home-container-title {
        display: flex;
        align-items: center;
    }

    .home-container-title span {
        /* 标题距离左边间距 */
        margin-left: 5px;
    }

    .home-aside {
        background-color:#118575;
        font-size: 10px !important;
    }

    .iconfont{
        /* 左侧图标与文本间距 */
        margin-right: 10px;
    }

    .el-menu {
        /* 去掉有边框线 */
        border-right: none;
    }

    .toggle-button{
        background-color:#4a5064;
        font-size: 10px;
        line-height: 24px;
        color: white;
        text-align: center;
        /* 文字之间的间距 */
        letter-spacing: 0.5em;
        /* 鼠标放上去，显示点击的小手 */
        cursor: pointer;
    }
    
</style>