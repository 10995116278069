<!-- UI结构 -->
<template>
    <div class="login-container">
        <div class="login-box">
            <!-- 登录表单 -->
            <!-- 通过ref来绑定实例对象loginFromRef -->
            <!-- :model进行数据绑定 loginFrom此时是一个对象-->
            <!-- :rules表单的验证规则 loginFromRules此时一个验证规则对象   label-width="0px"-->
            <el-form ref='loginFromRef' :model="loginFrom" :rules="loginFromRules" class="login_from">
                <!-- 用户名称 -->
                <!-- 通过prop来将userName绑定到loginFromRules验证对象 -->

                <el-form-item prop="userName">
                    <!-- v-model进行双向绑定到对象loginFrom -->
                    <el-input v-model="loginFrom.userName" placeholder="请设置您的用户名称"
                        prefix-icon="iconfont el-icon-third-user_name"></el-input>
                </el-form-item>

                <!-- 密码 -->
                <el-form-item prop="userPassword">
                    <el-input v-model="loginFrom.userPassword" type="password" placeholder="请设置您的用户密码"
                        prefix-icon="iconfont el-icon-third-user_password"></el-input>
                </el-form-item>

                <!-- 确认密码 -->
                <el-form-item prop="userPasswordAgain">
                    <el-input v-model="loginFrom.userPasswordAgain" type="password" placeholder="请再次输入您的用户密码"
                        prefix-icon="iconfont el-icon-third-user_password"></el-input>
                </el-form-item>

                <!-- 邮箱 -->
                <el-form-item prop="userEmail">
                    <el-input v-model="loginFrom.userEmail" placeholder="请输入您的qq邮箱"
                        prefix-icon="iconfont el-icon-third-user_name"></el-input>
                </el-form-item>

                <!-- 获取邮箱验证码 -->
                <el-form-item prop="userEmailCode" class="userEmailCodeClass">
                    <el-input v-model="loginFrom.userEmailCode" placeholder="请输入您的qq邮箱收到的验证码"
                        prefix-icon="iconfont el-icon-third-user_name">
                    </el-input>

                    <button @click="getCode" class="code-btn" :disabled="!show">
                        <span v-show="show">获取验证码</span>
                        <span v-show="!show" class="count">{{count}} s</span>
                    </button>
                </el-form-item>

                <!-- 注册 -->
                <el-from-item class="log-btn">
                    <el-button type="primary" @click="registerBtn" style="width: 400px;">注册</el-button>
                </el-from-item>

                <!-- 立即登录 -->
                <el-form-item class="login-other-btn">
                    <el-button type="text" @click="loginInNowBtn">立即登录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            //自定义  验证邮箱的规则
            var checkEmail = (rule, value, cb) => {
                // 验证邮箱的正则表达式
                const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
                if (regEmail.test(value)) {
                    // 合法的邮箱
                    return cb()
                }
                cb(new Error('请输入合法的邮箱'))
            }

            // 验证手机号的规则
            var checkMobile = (rule, value, cb) => {
                // 验证手机号的正则表达式
                const regMobile = /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
                if (regMobile.test(value)) {
                    return cb()
                }
                cb(new Error('请输入合法的手机号'))
            }

            //自定义  验证6位数字的验证码规则
            var checkEmailCode = (rule, value, cb) => {
                // 验证邮箱的正则表达式
                const regEmail = /^[0-9]+(.[0-9]{6})?$/
                if (regEmail.test(value)) {
                    // 合法的邮箱
                    return cb()
                }
                cb(new Error('请输入6位数字的邮箱验证码'))
            }

            return {
                // 登录表单的数据对象绑定
                loginFrom: {
                    userName: '',//用户名称
                    userPassword: '',//用户密码
                    userPasswordAgain: '',//再次输入用户密码
                    userEmail: '',//用户邮箱---1806339272@qq.com
                    userEmailCode: '',//用户邮箱验证码 
                },

                //表单验证规则对象
                loginFromRules: {
                    //验证用户名称是否合法
                    userName: [
                        { required: true, message: '请输入用户名称', trigger: 'blur' },
                        { min: 1, max: 10, message: '长度在1到10个字符', trigger: 'blur' },
                    ],

                    // 验证密码是否合法
                    userPassword: [
                        { required: true, message: '请输入用户密码', trigger: 'blur' },
                        { min: 6, max: 25, message: '长度在6到25个字符', trigger: 'blur' }
                    ],

                    // 验证再次输入密码是否合法
                    userPasswordAgain: [
                        { required: true, message: '请再次输入用户密码', trigger: 'blur' },
                        { min: 6, max: 25, message: '长度在6到25个字符', trigger: 'blur' }
                    ],

                    // 验证邮箱是否合法
                    userEmail: [
                        { required: true, message: '请输入用户qq邮箱', trigger: 'blur' },
                        { min: 6, max: 25, message: '长度在6到25个字符', trigger: 'blur' },
                        { validator: checkEmail, trigger: 'blur' }
                    ],

                    // 验证邮箱验证码是否合法
                    userEmailCode: [
                        { required: true, message: '请输入qq邮箱收到的验证码', trigger: 'blur' },
                        { min: 6, max: 6, message: '邮箱验证码长度为6个字符', trigger: 'blur' },
                        { validator: checkEmailCode, trigger: 'blur' }
                    ],
                },

                show: true, //是否显示获取验证码
                count: 0, //倒计时数字
                timer: 0,//定时器
            };
        },


        //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
        created() {
            console.log('created------register--');
            this.title = '注册';
            document.title = '注册';
        },

        methods: {
            // 获取短信验证码
            getCode() {

                if (!this.loginFrom.userEmail) {
                    this.$message.error('请输入用户qq邮箱');
                    return;   
                }

                const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
                if (regEmail.test(this.loginFrom.userEmail) == false) {
                    this.$message.error('请输入合法的qq邮箱');
                    return;
                }
               

                //axios请求
                // 验证码倒计时
                if (!this.timer) {
                    this.count = 60;
                    this.show = false;
                    this.getEmailCode();
                    this.timer = setInterval(() => {
                        if (this.count > 0 && this.count <= 60) {
                            this.count--;
                        } else {
                            this.show = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                        console.log('变化');
                        console.log(this.count);
                    }, 1000);
                }
            },

            // 获取邮箱验证码, async代表异步操作
            async getEmailCode() {
                const { data: res } = await this.$http.get('user/emailCode', {
                    params: {
                        userEmail: this.loginFrom.userEmail
                    },
                });
                console.log(res);
                if (res.status !== 200) {//请求失败
                    this.$message.error(res.msg);
                    return;
                }
                this.$message.success(res.msg);
            },

            //注册操作事件
            registerBtn() {
                console.log('注册请求');
                // async表示异步请求
                this.$refs.loginFromRef.validate(async valid => {

                    if (!this.loginFrom.userName) {
                        this.$message.error('请输入用户名称');
                        return;
                    }
                    if (!this.loginFrom.userPassword) {
                        this.$message.error('请输入用户密码');
                        return;
                    }
                    if (!this.loginFrom.userPasswordAgain) {
                        this.$message.error('请再次输入用户密码');
                        return;
                    }
                    if (this.loginFrom.userPasswordAgain != this.loginFrom.userPassword) {
                        this.$message.error('二次输入的用户密码不同，请重新输入');
                        return;
                    }
                    if (!this.loginFrom.userEmail) {
                        this.$message.error('请输入用户qq邮箱');
                        return;
                    }
                    if (!this.loginFrom.userEmailCode) {
                        this.$message.error('请输入用户qq邮箱收到的验证码');
                        return;
                    }
                    const { data: res } = await this.$http.post("user/register",
                        {
                            "userName": this.loginFrom.userName,
                            "password": this.$md5(this.loginFrom.userPassword),
                            "userEmail": this.loginFrom.userEmail,
                            "userEmailCode": this.loginFrom.userEmailCode,
                        });
                    console.log(res);
                    if (res.status !== 200) {//请求失败
                        this.$message.error(res.msg);
                        return;
                    }
                    this.$message.success('注册成功');
                    console.log('请求成功了');
                    window.sessionStorage.setItem('userName', res.data.userName);
                    // this.Common.userName = res.data.userName;
                    // this.Common.isSuperuser = res.data.isSuperuser;
                    // this.Common.isSuperuser = res.data.isSuperuser;

                    // 2. 通过编程式导航跳转到后台主页，路由地址是 /home
                    this.$router.push('/home');
                })
            },

            //进入登录界面
            loginInNowBtn() {
                console.log('loginInNowBtn');
                this.$router.push('/login');
            },

        },
    }

</script>



<style lang="less" scoped>

    /* 主布局 */
    .login-container {
        width: 100%;
        height: 100%;
        background-color: rgb(242, 242, 242);
    }

    /* 注册界面模块 */
    .login-box {
        width: 450px;
        height: 500px;
        background-color: #ffffff;
        /* 设置圆角 */
        border-radius: 3px;
        /* 开启绝对定位 */
        position: absolute;
        left: 50%;
        top: 50%;
        /* 位移操作，参考自己的位置来平移，向X轴平移，填正数往右平移，填负数，往左平移，向Y轴平移，填正数往下平移，填负数，往上平移*/
        transform: translate(-50%, -50%);
    }

    /* 注册界面布局 */
    .login_from {
        /* 开启绝对定位 */
        position: absolute;
        top: 20px;
        width: 100%;
        /* 设置左右间距 */
        padding: 0 10px;
        /* 设置表单样式 */
        box-sizing: border-box;
    }

    .login_from /deep/ .el-input__inner {
        font-size: 16px !important;
        margin-top: 5px;
    }

    /* 注册 */
    .log-btn {
        /* 设置为弹性框模型 */
        display: flex;
    }

    .log-btn /deep/ .el-button {
        justify-content: center;
        margin-top: 20px;
        margin-left: 5px;
    }

    .userEmailCodeClass {
        position: relative;
    }

    /* 获取验证码 */
    .code-btn {
        width: 100px;
        height: 36px;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 222;
        color: #ef8466;
        font-size: 14px;
        border: none;
        border-left: 1px solid #bababa;
        /* padding-left: 10px; */
        padding: 5px;
        margin-top: 2px;
        background-color: #fff;
        cursor: pointer;
    }

    /* 立即登录---样式 */
    .login-other-btn {
        margin-top: 20px;
    }

    .login-other-btn /deep/ .el-button {
        justify-content: center !important;
        margin-left: 5px;
        width: 100px !important;
    }
</style>