<template>
    <div>
        <!-- 顶部菜单栏操作 el-row配置行,el-col配置列-->
        <el-row :gutter="1" class="articleTabClass">
            <el-col>
                <!-- :span="12" -->
                <el-button type="primary" @click='goBackPage'>
                    返回
                </el-button>

                <el-button type="primary" @click='saveArticleInformation'>
                    保存
                </el-button>

                <el-button type="primary" @click='clearArticleInformation'>
                    清空
                </el-button>

                <el-select class="articleTypeStyle" v-model="articleType" placeholder="请选择文章分类"
                    @change='newTypeFunction'>
                    <el-option key="999" value="新建">
                    </el-option>
                    <el-option v-for="item in articleTypeList" :key="item.typeId" :value="handleArticleType(item)">
                    </el-option>
                </el-select>

                <!-- multiple是否支持多选  @change:选中值发生变化时触发-->
                <el-select class="articleTagStyle" v-model="articleTag" placeholder="请选择文章标签" :multiple="selectShow"
                    @change='newTagFunction'>
                    <el-option key="999" value="新建">
                    </el-option>

                    <template>
                        <div v-for="item in articleTagList" :key="item.tagId">
                            <el-option v-if="item.tagName.indexOf(filterString)>=0" :label="item.tagName"
                                :value="item.tagName"></el-option>
                        </div>
                    </template>
                </el-select>

                <el-input class="filterStringTypeStyle" v-model="filterString" placeholder="请输入过滤的字符串"></el-input>
            </el-col>
        </el-row>

        <!-- 添加文章分类的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
        <el-dialog title="添加分类" :visible.sync="addArticleTypeDialogVisible" width="50%"
            @close="addArticleTypeDialogClose">
            <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
            <el-form :model="addTypeForm" :rules="addTypeFormRules" ref="addTypeFormRef" label-width="100px">
                <!-- prop验证规则属性 v-model双向绑定数据-->
                <el-form-item label="父级" prop="parentName">
                    <el-select v-model="addTypeForm.parentName" placeholder="请选择父级">
                        <el-option label="顶级" value="顶级"></el-option>
                        <!-- 外层for循环，里面el-option采用v-if条件判断 -->
                        <template>
                            <div v-for="(item, index) in articleTypeParentList" :key="index">
                                <!-- 判断是否父级类 -->
                                <el-option v-if="item.grate == 1 " :label="item.parentName" :value="item.parentName">
                                </el-option>
                            </div>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="分类名称" prop="subName">
                    <el-input v-model="addTypeForm.subName"></el-input>
                </el-form-item>

                <el-form-item label="分类介绍" prop="content">
                    <el-input v-model="addTypeForm.content"></el-input>
                </el-form-item>
            </el-form>

            <!-- 底部区域 -->
            <div slot="footer" class="dialog-footer">
                <el-button @click="addArticleTypeDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addArticleType">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 添加文章标签的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
        <el-dialog title="添加标签" :visible.sync="addArticleTagDialogVisible" width="50%"
            @close="addArticleTagDialogClose">
            <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
            <el-form :model="addTagForm" :rules="addTagFormRules" ref="addTagFormRef" label-width="100px">
                <!-- prop验证规则属性 v-model双向绑定数据-->
                <el-form-item label="标签名称" prop="tagName">
                    <el-input v-model="addTagForm.tagName"></el-input>
                </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <div slot="footer">
                <el-button @click="addArticleTagDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addArticleTag">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 卡片视图区域  -->
        <el-card class="articleViewClass">
            <el-row :gutter="24" class="articleTitleClass">
                <el-col :span="24">
                    <el-input v-model="articleTitle" placeholder="请输入文章标题"></el-input>
                </el-col>
            </el-row>

            <el-row :gutter="24" class="articleIntroductionStyle">
                <el-col :span="24">
                    <el-input v-model="articleIntroduction" placeholder="请输入文章简介"></el-input>
                </el-col>
            </el-row>

            <!-- 富文本编辑 -->
            <div class="articleContentStyle">
                <!-- 工具栏 -->
                <Toolbar class="toolBarClass" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />

                <!-- 编辑器 -->
                <Editor class="editorClass" v-model="html" :defaultConfig="editorConfig" :mode="mode"
                    @onCreated="onCreated" @onChange="onChange" />
            </div>
        </el-card>

    </div>
</template>




<script>
    import '@wangeditor/editor/dist/css/style.css'    //引入wangeditor的样式
    //  ref, shallowRef, 
    import { onBeforeUnmount, onMounted } from "vue";
    import { Editor, Toolbar, IEditorConfig } from '@wangeditor/editor-for-vue'

    export default {
        // 注册组建信息
        components: { Editor, Toolbar },

        // 数据处理
        data() {
            return {
                articleTitle: '',       //文章标题
                articleIntroduction: '', //文章简介
                articleType: '',    //文章分类                    
                articleTag: [],     //文章标签，支持多个标签
                filterString: '',        //过滤的字符串

                editor: null, //实例
                html: '<p>文章正文</p>',
                toolbarConfig: {},   //工具栏配置  head:'标题'

                // readOnly:true,
                //编译器配置
                editorConfig: {
                    placeholder: '请输入内容...',
                    MENU_CONF: {
                        color: {
                            colors: [
                                "rgb(0, 0, 0)", "rgb(38, 38, 38)", "rgb(89, 89, 89)", "rgb(140, 140, 140)", "rgb(191, 191, 191)",
                                "rgb(217, 217, 217)", "rgb(233, 233, 233)", "rgb(245, 245, 245)", "rgb(250, 250, 250)", "rgb(255, 255, 255)",
                                "rgb(225, 60, 57)", "rgb(231, 95, 51)", "rgb(235, 144, 58)", "rgb(245, 219, 77)", "rgb(114, 192, 64)",
                                "rgb(89, 191, 192)", "rgb(66, 144, 247)", "rgb(54, 88, 226)", "rgb(106, 57, 201)", "rgb(216, 68, 147)",
                                "rgb(251, 233, 230)", "rgb(252, 237, 225)", "rgb(252, 239, 212)", "rgb(252, 251, 207)", "rgb(231, 246, 213)",
                                "rgb(218, 244, 240)", "rgb(217, 237, 250)", "rgb(224, 232, 250)", "rgb(237, 225, 248)", "rgb(246, 226, 234)",
                                "rgb(255, 163, 158)", "rgb(255, 187, 150)", "rgb(255, 213, 145)", "rgb(255, 251, 143)", "rgb(183, 235, 143)",
                                "rgb(135, 232, 222)", "rgb(145, 213, 255)", "rgb(173, 198, 255)", "rgb(211, 173, 247)", "rgb(255, 173, 210)",
                                "rgb(255, 77, 79)", "rgb(255, 122, 69)", "rgb(255, 169, 64)", "rgb(255, 236, 61)", "rgb(115, 209, 61)",
                                "rgb(54, 207, 201)", "rgb(64, 169, 255)", "rgb(89, 126, 247)", "rgb(146, 84, 222)", "rgb(247, 89, 171)",
                                "rgb(207, 19, 34)", "rgb(212, 56, 13)", "rgb(212, 107, 8)", "rgb(212, 177, 6)", "rgb(56, 158, 13)",
                                "rgb(8, 151, 156)", "rgb(9, 109, 217)", "rgb(29, 57, 196)", "rgb(83, 29, 171)", "rgb(196, 29, 127)",
                                "rgb(130, 0, 20)", "rgb(135, 20, 0)", "rgb(135, 56, 0)", "rgb(97, 71, 0)", "rgb(19, 82, 0)",
                                "rgb(0, 71, 79)", "rgb(0, 58, 140)", "rgb(6, 17, 120)", "rgb(34, 7, 94)", "rgb(120, 6, 80)",

                                // 新增字体颜色
                                "rgb(128,128,128)"
                            ]
                        }
                    }
                },

                mode: 'default',        // or 'simple'，  样式

                articleContent: '',     //存储内容
                articleTypeParentList: [],  //文章一级分类列表，所有的一级分类
                addArticleTypeDialogVisible: false, // 控制添加文章分类对话框的显示与隐藏
                addTypeForm: {      //添加文章分类的表单数据对象
                    parentName: '', //父类
                    subName: '',    //分类名称---有可能是父类名称，也有可能是子类名称
                    content: '请填写分类描述',
                },

                addTypeFormRules: { // 添加文章分类表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                    parentName: [
                        { required: true, message: '请选择父级', trigger: 'blur' }
                    ],
                    subName: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 1, max: 15, message: '分类名称长度在1到15个字符', trigger: 'blur' }
                    ],
                },

                articleTypeList: [],    //文章分类列表
                articleTagList: [],     //文章标签列表
                selectShow: true,       //是否多选
                addArticleTagDialogVisible: false,  // 控制添加文章标签对话框的显示与隐藏

                addTagForm: {       //添加文章标签的表单数据对象
                    tagName: '',    //标签名称
                },

                // 添加文章标签表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addTagFormRules: {
                    tagName: [
                        { required: true, message: '请输入标签名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '标签名称长度在2到15个字符', trigger: 'blur' }
                    ],
                },

            }
        },



        // 编辑器销毁
        beforeDestroy() {
            const editor = this.editor;
            if (editor == null) {
                return
            }
            // 【注意】组件销毁时，及时销毁编辑器
            editor.destroy();
        },

        //创建
        created() {
            this.getArticleTypeList();
            this.getArticleTagList();
        },

        // 方法
        methods: {
            // 返回上一页
            goBackPage() {
                this.$router.go(-1);
            },

            // 获取文章分类列表, async代表异步操作
            async getArticleTypeList() {
                const { data: res } = await this.$http.get('/article/type/list', {
                    params: {
                        page: 999, //文章分类page传入999，代表获取全部文章标题列表
                    }
                });
                this.articleTypeList = res.typeList;
                this.articleTypeParentList = res.typeParentList;
            },

            // 获取文章标签列表, async代表异步操作
            async getArticleTagList() {
                const { data: res } = await this.$http.get('/article/tag/list', {
                    params: {
                        page: 999, //文章标签page传入999，代表获取全部文章标签列表
                    }
                });
                this.articleTagList = res.tagList;
            },

            // 处理文章分类相关数据
            handleArticleType: function (item) {
                return item.parentName + "/" + item.subName;
            },

            // 新建文章分类功能功能
            newTypeFunction() {
                if (this.articleType == '新建') {
                    this.articleType = '';
                    this.addArticleTypeDialogVisible = true;
                }
            },

            // 监听添加文章分类对话关闭事件
            addArticleTypeDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addTypeFormRef !== undefined) {
                    this.addTypeFormRef.resetFields();
                }
            },

            // 点击确定按钮，添加文章分类信息
            addArticleType() {
                let userEmail = window.sessionStorage.getItem('userEmail');
                console.log("--9999999999---")
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                this.$refs.addTypeFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/type/add', {
                        parentName: this.addTypeForm.parentName,
                        subName: this.addTypeForm.subName,
                        content: this.addTypeForm.content,
                        token: window.sessionStorage.getItem('token'),
                    });
                    if (res.status !== 200) {
                        return this.$message.error('新建文章分类失败');
                    }
                    this.$message.success('新建文章分类成功');
                    this.addArticleTypeDialogVisible = false;  // 隐藏添加的对话
                    this.getArticleTypeList();          //重新获取平台列表
                })
            },

            // 新建标签功能
            newTagFunction() {
                if (this.articleTag[this.articleTag.length - 1] == '新建') {
                    this.articleTag.pop();
                    this.selectShow = false;
                    // 隐藏添加的对话
                    this.addArticleTagDialogVisible = true;
                }
            },

            // 监听添加标签对话关闭事件
            addArticleTagDialogClose() {
                this.selectShow = true;
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addTagFormRef !== undefined) {
                    this.addTagFormRef.resetFields();
                }
            },

            // 点击确定按钮，添加标签信息
            addArticleTag() {
                this.selectShow = true;
                this.$refs.addTagFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    console.log('数据请求');
                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/tag/add', {
                        tagName: this.addTagForm.tagName,
                        token: window.sessionStorage.getItem('token'),
                    });
                    console.log(res);
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    this.$message.success('新建标签名称成功');

                    // 隐藏添加的对话
                    this.addArticleTagDialogVisible = false;

                    // 重新获取标签列表
                    this.getArticleTagList();
                })
            },

            // 保存文章信息
            async saveArticleInformation() {
                console.log('保存文章信息-----');
                console.log(this.articleType);
                console.log(this.articleTag);
                console.log(this.articleTitle);
                console.log(this.articleContent);
                if (this.articleType == '') {
                    this.$message.error("请选择文章类型")
                    return;
                }
                var articleTypeId = 0;  //文章分类对应的ID
                for (let index = 0; index < this.articleTypeList.length; index++) {
                    const tempType = this.articleTypeList[index];
                    if (this.articleType == this.handleArticleType(tempType)) {
                        articleTypeId = tempType.typeId;
                        break;
                    }
                }

                if (this.articleTag.length == 0) {
                    this.$message.error("请选择文章标签")
                    return;
                }
                var articleTagId = "";  //文章标签对应的ID,多标签Id以;进行分割区分
                for (let index = 0; index < this.articleTag.length; index++) {
                    const tempTag = this.articleTag[index];
                    for (let indexList = 0; indexList < this.articleTagList.length; indexList++) {
                        const tempListTag = this.articleTagList[indexList];
                        if (tempTag == tempListTag.tagName) {
                            if (articleTagId == "") {
                                articleTagId = tempListTag.tagId
                            } else {
                                articleTagId = articleTagId + ";" + tempListTag.tagId
                            }
                            break;
                        }
                    }
                }

                if (this.articleTitle == '') {
                    this.$message.error("请输入文章标题")
                    return;
                }
                if (this.articleIntroduction == '') {
                    this.$message.error("请输入文章简介")
                    return;
                }
                // 预校验通过,
                const { data: res } = await this.$http.post('/article/article/add', {
                    title: this.articleTitle,       //文章标题
                    introduction: this.articleIntroduction,  //文章简介
                    content: this.articleContent,   //文章内容
                    articleTypeId: articleTypeId,   //文章类型ID
                    articleTagId: articleTagId,     //文章标签，可能有多个标签，多标签Id以;进行分割区分
                    token: window.sessionStorage.getItem('token'),
                });
                console.log(res.data);
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('新建文章成功');
            },

            // 清空当前页面的内容
            clearArticleInformation() {
                this.articleType = '';
                console.log('清空当前页面的内容-------')
                this.articleTag = [];
                this.articleTitle = '';
                this.html = '<p>文章正文</p>';
                const editor = this.editor;
                if (editor == null) {
                    return
                }
                editor.content = [];
            },


            // 编辑器在创建的时候
            onCreated(editor) {
                console.log('onCreated------', editor)
                this.editor = Object.seal(editor);  // 一定要用 Object.seal() ，否则会报错

                console.log('-----getAllMenKeys-------', this.editor.getConfig());


                // this.editorConfig = this.editor.getConfig();
                // this.editorConfig.MENU_CONF["color"] = {
                //     '#ff0000': '红色',
                // }

                // this.editor.config.colors = {"#808080":"灰色"}

                // this.editorConfig.NENU_CONF['color'] = {
                //     colors: ['#808080']
                // }

            },


            // 自定义校验图片
            customCheckImageFn: function (src, alt, url) {
                if (!src) {
                    return
                }
                if (src.indexOf('http') !== 0) {
                    return '图片网址必须以 http/https 开头'
                }
                return true

                // 返回值有三种选择：
                // 1. 返回 true ，说明检查通过，编辑器将正常插入图片
                // 2. 返回一个字符串，说明检查未通过，编辑器会阻止插入。会 alert 出错误信息（即返回的字符串）
                // 3. 返回 undefined（即没有任何返回），说明检查未通过，编辑器会阻止插入。但不会提示任何信息
            },


            // 转换图片链接
            customParseImageSrc: function (src) {
                if (src.indexOf('http') !== 0) {
                    return `http://${src}`
                }
                return src
            },


            // 输入信息改变的时候
            onChange(editor) {
                // JSON.stringify目的是将对象转换为字符串，与JSON.parse是一对
                // this.articleContent = JSON.stringify(editor.children);

                console.log('articleContent------', this.articleContent);
                console.log('html------', this.html);
                this.articleContent = this.editor.getHtml();

                // // 编辑器配置 MENU_CONF: {} 
                // const editorConfig = { };
                // editorConfig.placeholder = '请输入内容';

                // // 插入图片
                // editorConfig.MENU_CONF['insertImage'] = {
                //     onInsertedImage(imageNode) {                    // JS 语法
                //         if (imageNode == null) return
                //         const { src, alt, url, href } = imageNode
                //         console.log('inserted image', src, alt, url, href)
                //     },
                //     // checkImage: customCheckImageFn, // 也支持 async 函数
                //     // parseImageSrc: customParseImageSrc, // 也支持 async 函数
                // };
            },

            // 自定义粘贴
            customPaste(editor, event, callback) {
                console.log('ClipboardEvent 粘贴事件对象', event)
                // 自定义插入内容
                // editor.insertText('xxx')

                // 返回值（注意，vue 事件的返回值，不能用 return）
                // callback(false) // 返回 false ，阻止默认粘贴行为
                callback(true) // 返回 true ，继续默认的粘贴行为
            },


        },

    }
</script>







<!-- 样式 -->
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
    /* 顶部菜单栏操作样式 */
    .articleTabClass {
        padding-bottom: 10px;
    }

    /* 文章分类的样式 */
    .articleTypeStyle {
        padding-left: 10px;
        width: 150px;
    }

    /* 文章标签的样式 */
    .articleTagStyle {
        padding-left: 10px;
        width: 360px;
    }

    /* 模糊过滤的样式 */
    .filterStringTypeStyle {
        margin-left: 10px;
        width: 180px;
    }

    /* 文章标题的样式 */
    .articleViewClass {
        padding-bottom: 10px;
    }

    /* 文章标题 */
    .articleTitleClass {
        padding-bottom: 10px;
    }

    /* 文章简介 */
    .articleIntroductionStyle {
        height: 40px;
        padding-bottom: 10px;
    }

    /* 文章正文 */
    .articleContentStyle {
        border: 1px solid #ccc;
        margin-top: 10px;
    }

    /* 工具栏 */
    .toolBarClass {
        border-bottom: 1px solid #ccc
    }

    /* 编辑器的样式 */
    .editorClass {
        height: 400px;
    }
</style>