<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<!-- 行为、事件 -->
<script>
  export default {
    name: 'app'
  }
</script>


<!-- 样式 -->
<style>
</style>
