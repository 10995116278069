<template>
    <!-- 消费记录 -->
    <div>
        <div class="bookClassNavi">
            <!-- 面包屑导航---- -->
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/welcome' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>个人账本</el-breadcrumb-item>
                <el-breadcrumb-item>消费记录</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 卡片视图区域  -->
        <el-card class="box-card">
            <el-row>
                <!-- 添加按钮 -->
                <el-button class="addBookStyle" type="primary" @click='addBookDialogVisible = true'>
                    新建消费
                </el-button>
                <input class="outputListUpload" type="file" ref="upload" accept=".xls,.xlsx">
                <span>总数据:{{importDataTotal}}条, 正在导入第{{importDataNum}}条</span>

                <el-input class="filterStringTypeStyle" v-model="filterString" placeholder="请输入商家过滤的字符串"></el-input>
            </el-row>

            <!-- 搜索总样式 -->
            <el-row class="searchBooksTotalStyle">
                <el-select class="bookTypeStyle" v-model="bookTypeId" placeholder="请选择消费分类">
                    <el-option v-for="item in allTypeList" :key="item.typeId" :value="item.allName">
                    </el-option>
                </el-select>

                <el-select class="paymentMethodStyle" v-model="paymentMethodId" placeholder="请选择支付方式">
                    <el-option v-for="item in paymentMethodList" :key="item.typeId" :value="item.allName">
                    </el-option>
                </el-select>

                <el-select class="businessStyle" v-model="businessValue" placeholder="请选择商家名称">
                    <template>
                        <div v-for="(item, index) in businessList" :key="index">
                            <el-option v-if="item.subName.indexOf(filterString)>=0" :label="item.allName"
                                :value="item.allName"></el-option>
                        </div>
                    </template>

                </el-select>

                <!-- 年、月、日 切换  @change="changeDateFunction(scope.row)"-->
                <el-radio-group class="dateGroupStyle" v-model="dateRadio" @change="changeDateFunction">
                    <el-radio-button label="年"></el-radio-button>
                    <el-radio-button label="月"></el-radio-button>
                    <el-radio-button label="日"></el-radio-button>
                </el-radio-group>

                <!-- :type代表双向绑定;  selectDatePlace、选择日期时间 -->
                <el-date-picker class="dateSelecttyle" v-model="selectDate" :type="selectDateType"
                    :placeholder="selectDatePlace" :format="selectDateFormat" value-format="yyyy-MM-dd">
                </el-date-picker>

                <el-button class="searchBookTypeStyle" type="primary" @click='searchBookTypeInformation'>
                    搜索
                </el-button>

                <el-button class="resetBookTypeStyle" type="primary" @click='resetBookTypeInformation'>
                    重置
                </el-button>

                <div>
                    <span>总金额:{{totalMoney}}</span>
                </div>
            </el-row>



            <!-- 平台列表  ：data绑定数据源，prop指定参数对应的值， border边框，stripe隔行变色-->
            <el-table :data="articleTypeList" border stripe style="width: 100%"
                :header-cell-style="{'text-align':'center'}">
                <el-table-column prop="bookId" label="消费ID" width="65" align="center">
                </el-table-column>
                <el-table-column prop="money" label="金额" width="70" align="center">
                </el-table-column>
                <el-table-column prop="typeId" label="消费分类" width="110" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{handleBookTypeID(scope.row)}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="consumerId" label="消费者" width="70" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{handleBookConsumerId(scope.row)}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="paymentMethodId" label="支付方式" width="160" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{handleBookPaymentMethodId(scope.row)}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="businessId" label="商家名称" width="200" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{handleBookBusinessId(scope.row)}}
                        </span>
                    </template>
                </el-table-column>

                <el-table-column prop="mainType" label="收/支" width="60" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{handleMainType(scope.row)}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="usedTime" label="支付时间" width="160" align="center">
                </el-table-column>
                <el-table-column label="是否删除" width="80">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isDeleted" @change="bookTypeStateChanged(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column label="操作">
                    <!-- 作用域插槽的使用 -->
                    <template slot-scope="scope">
                        <!-- enterablez 鼠标是否可进入到 tooltip 中 自动隐藏-->
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <!-- 编辑按钮 -->
                            <el-button type="primary" icon="el-icon-edit" @click="showArticleTypeEditDialog(scope.row)">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 页码 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="booksInformation.page" :page-size="articlePageSize"
                layout="total, prev, pager, next, jumper" :total="articleTotal" class="articlePage-list">
            </el-pagination>




            <!-- 添加消费记录的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog class="addBookDialogClass" title="添加消费记录" :visible.sync="addBookDialogVisible" width="50%"
                @close="addArticleTypeDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form class="addBookFormClass" :model="addForm" :rules="addFormRules" ref="addFormRef"
                    label-width="100px">
                    <!-- prop验证规则属性 v-model双向绑定数据-->
                    <el-form-item label="消费金额" prop="money">
                        <el-input class="businessIdTypeStyle" v-model="addForm.money"></el-input>
                    </el-form-item>

                    <el-form-item label="支付方式" prop="paymentMethodId">
                        <el-select class="businessIdTypeStyle" v-model="addForm.paymentMethodId" placeholder="请选择支付方式">
                            <template>
                                <div v-for="(item, index) in paymentMethodList" :key="index">
                                    <el-option :label="item.allName" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="商家名称" prop="businessId">
                        <el-select class="businessIdTypeStyle" v-model="addForm.businessId" placeholder="请选择商家名称">
                            <template>
                                <div v-for="(item, index) in businessList" :key="index">
                                    <el-option v-if="item.subName.indexOf(filterString)>=0" :label="item.allName"
                                        :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                        <el-input class="filterStringTypeStyle" v-model="filterString"
                            placeholder="请输入过滤的字符串"></el-input>
                    </el-form-item>

                    <el-form-item label="消费时间" prop="usedTime">
                        <el-date-picker class="businessIdTypeStyle" v-model="addForm.usedTime" type="datetime"
                            placeholder="选择日期时间" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">>
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="消费分类" prop="typeId">
                        <el-select class="businessIdTypeStyle" v-model="addForm.typeId" placeholder="请选择消费分类">
                            <template>
                                <div v-for="(item, index) in typeList" :key="index">
                                    <el-option v-if="item.subName.indexOf(filterTypeString)>=0" :label="item.allName" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                        <el-input class="filterStringTypeStyle" v-model="filterTypeString"
                        placeholder="请输入过滤的消费分类"></el-input>
                    </el-form-item>

                    <el-form-item label="消费者" prop="consumerId">
                        <el-select class="businessIdTypeStyle" v-model="addForm.consumerId" placeholder="请选择消费者">
                            <template>
                                <div v-for="(item, index) in customList" :key="index">
                                    <el-option :label="item.subName" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="支出/收入" prop="mainType">
                        <el-select class="businessIdTypeStyle" v-model="addForm.mainType" placeholder="请选择类型">
                            <template>
                                <div v-for="(item, index) in mainTypeList" :key="index">
                                    <el-option :label="item.name" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="消费说明" prop="remarks">
                        <el-input class="businessRemarksTypeStyle" v-model="addForm.remarks"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer">
                    <el-button @click="addBookDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addArticleType">确 定</el-button>
                </div>
            </el-dialog>



            <!-- 修改文章分类的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="修改分类信息" :visible.sync="editBookDialogVisible" width="50%" @close="editUserDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form class="addBookFormClass" :model="editForm" :rules="editFormRules" ref="editFormRef"
                    label-width="100px">
                    <el-form-item label="消费金额" prop="money">
                        <el-input class="businessIdTypeStyle" v-model="editForm.money"></el-input>
                    </el-form-item>

                    <el-form-item label="支付方式" prop="paymentMethodId">
                        <el-select class="businessIdTypeStyle" v-model="editForm.paymentMethodId" placeholder="请选择支付方式">
                            <template>
                                <div v-for="(item, index) in paymentMethodList" :key="index">
                                    <el-option :label="item.allName" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="商家名称" prop="businessId">
                        <el-select class="businessIdTypeStyle" v-model="editForm.businessId" placeholder="请选择商家名称">
                            <template>
                                <div v-for="(item, index) in businessList" :key="index">
                                    <el-option v-if="item.subName.indexOf(filterString)>=0" :label="item.allName"
                                        :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                        <el-input class="filterStringTypeStyle" v-model="filterString"
                            placeholder="请输入过滤的字符串"></el-input>
                    </el-form-item>

                    <el-form-item label="消费时间" prop="usedTime">
                        <el-date-picker class="businessIdTypeStyle" v-model="editForm.usedTime" type="datetime"
                            placeholder="选择日期" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">>
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="消费分类" prop="typeId">
                        <el-select class="businessIdTypeStyle" v-model="editForm.typeId" placeholder="请选择消费分类">
                            <template>
                                <div v-for="(item, index) in typeList" :key="index">
                                    <el-option v-if="item.subName.indexOf(filterTypeString)>=0" :label="item.allName" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                        <el-input class="filterStringTypeStyle" v-model="filterTypeString"
                        placeholder="请输入过滤的消费分类"></el-input>
                    </el-form-item>

                    <el-form-item label="消费者" prop="consumerId">
                        <el-select class="businessIdTypeStyle" v-model="editForm.consumerId" placeholder="请选择消费者">
                            <template>
                                <div v-for="(item, index) in customList" :key="index">
                                    <el-option :label="item.subName" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="支出/收入" prop="mainType">
                        <el-select class="businessIdTypeStyle" v-model="editForm.mainType" placeholder="请选择类型">
                            <template>
                                <div v-for="(item, index) in mainTypeList" :key="index">
                                    <el-option :label="item.name" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="消费说明" prop="remarks">
                        <el-input class="businessRemarksTypeStyle" v-model="editForm.remarks"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer">
                    <el-button @click="editBookDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editBookInfor">确 定</el-button>
                </div>
            </el-dialog>
        </el-card>

    </div>
</template>





<!-- 行为 -->
<script>
    import { read, utils } from 'xlsx'
    export default {
        // 数据处理
        data() {
            return {
                //获取文章分类列表的所需要设置的参数
                booksInformation: {
                    page: 1, //消费记录列表页码数，默认每页10条数据
                    typeId: 0, //消费分类，未知状态
                    paymentMethodId: 0,  //支付方式Id，未知状态
                    businessId: 0,       //商户名称Id，未知状态
                },

                importDataTotal: 0,//导入数据总数
                importDataNum: 0,//正在导入数据的数量

                // 搜索功能
                bookTypeId: '',         //消费分类
                paymentMethodId: '',    //支付方式
                businessValue: '',      //商家名称
                totalMoney: 0,          //总金额

                dateRadio: '日',                    //选择年、月、日的切换
                selectDate: '',                    //选择的时间
                selectDateType: 'date',            //选择的时间类型：年、月、日
                selectDatePlace: '选择日期',        //选择的时间对应提示
                selectDateFormat: 'yyyy-MM-dd',    //选择的时间对应提示

                articleTotal: 0,  //消费记录总数
                articlePageSize: 10, //默认每页10条数据

                //文章分类列表---每页最多10条数据
                articleTypeList: [],

                //所有消费分类列表，支付方式、消费者、商户等分类除外
                allTypeList: [],

                //二级分类列表
                typeList: [],

                //支付方式列表
                paymentMethodList: [],

                //商家列表----消费场所
                businessList: [],

                filterString: '',       //在商家列表中进行模糊搜索
                filterTypeString: '',  //在消费分类列表中进行模糊搜索

                //消费者列表
                customList: [],

                //主分类列表，0是未知，1为支出性，2为收入性
                mainTypeList: [{ "typeId": 0, "name": "未知" }, { "typeId": 1, "name": "支出" }, { "typeId": 2, "name": "收入" }],

                // 控制添加文章分类对话框的显示与隐藏
                addBookDialogVisible: false,

                //添加消费记录的表单数据对象
                addForm: {
                    money: 0.0,            //消费金额
                    typeId: '',           //消费分类ID
                    paymentMethodId: '',  //支付方式
                    businessId: '',       //商家名称
                    consumerId: '',      //消费者
                    usedTime: '',        //消费时间
                    mainType: '',        //未知0，支出1、收入2
                    remarks: '',          //消费说明、描述
                },

                // 添加文章分类表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addFormRules: {
                    money: [
                        { required: true, message: '请输入消费金额', trigger: 'blur' }
                    ],
                    typeId: [
                        { required: true, message: '请选择消费分类', trigger: 'blur' }
                    ],
                    paymentMethodId: [
                        { required: true, message: '请选择消费分类', trigger: 'blur' }
                    ],
                    businessId: [
                        { required: true, message: '请选择商家名称', trigger: 'blur' }
                    ],
                    consumerId: [
                        { required: true, message: '请选择消费者', trigger: 'blur' }
                    ],
                    usedTime: [
                        { required: true, message: '请选择消费时间', trigger: 'blur' }
                    ],
                    mainType: [
                        { required: true, message: '请选择支出/收入', trigger: 'blur' }
                    ],
                    remarks: [
                        { required: true, message: '请输入消费说明', trigger: 'blur' },
                    ],
                },

                // 控制修改文章分类对话框的显示与隐藏
                editBookDialogVisible: false,

                //父类（一级）是否顶级，如果是true, 否则是false,
                editParentVisible: false,

                //获取到修改平台的数据对象
                editForm: {},

                // 修改平台表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                editFormRules: {
                    money: [
                        { required: true, message: '请输入消费金额', trigger: 'blur' }
                    ],
                    typeId: [
                        { required: true, message: '请选择消费分类', trigger: 'blur' }
                    ],
                    paymentMethodId: [
                        { required: true, message: '请选择消费分类', trigger: 'blur' }
                    ],
                    businessId: [
                        { required: true, message: '请选择商家名称', trigger: 'blur' }
                    ],
                    consumerId: [
                        { required: true, message: '请选择消费者', trigger: 'blur' }
                    ],
                    usedTime: [
                        { required: true, message: '请选择消费时间', trigger: 'blur' }
                    ],
                    mainType: [
                        { required: true, message: '请选择支出/收入', trigger: 'blur' }
                    ],
                    remarks: [
                        { required: true, message: '请输入消费说明', trigger: 'blur' },
                    ], money: [
                        { required: true, message: '请输入消费金额', trigger: 'blur' }
                    ],
                    typeId: [
                        { required: true, message: '请选择消费分类', trigger: 'blur' }
                    ],
                    paymentMethodId: [
                        { required: true, message: '请选择消费分类', trigger: 'blur' }
                    ],
                    businessId: [
                        { required: true, message: '请选择商家名称', trigger: 'blur' }
                    ],
                    consumerId: [
                        { required: true, message: '请选择消费者', trigger: 'blur' }
                    ],
                    usedTime: [
                        { required: true, message: '请选择消费时间', trigger: 'blur' }
                    ],
                    mainType: [
                        { required: true, message: '请选择支出/收入', trigger: 'blur' }
                    ],
                    remarks: [
                        { required: true, message: '请输入消费说明', trigger: 'blur' },
                    ],
                }
            }
        },

        //创建
        created() {
            this.getArticleTypeList();
        },


        // 绑定
        mounted() {
            this.$refs.upload.addEventListener('change', e => {//绑定监听表格导入事件
                this.readExcel(e);
            })
        },

        // 方法
        methods: {
            // 根据年月信息，获取当前月份最大的天数
            getMonthLength(year, month) {
                return new Date(year, month, 0).getDate();
            },

            // 字符串是否为空
            isNotEmptyStr(s) {
                if (typeof s == 'string' && s.length > 0) {
                    return true
                }
                return false
            },

            // 小于10的拼接上0的字符串
            addZero(s) {
                return (s < 10 ? ('0' + s) : s);
            },

            // 获取当前时间
            getCurrentDateTime() {
                const time = new Date();
                const year = time.getFullYear() + ''
                const month = time.getMonth() + 1 + ''
                const day = time.getDate() + ''
                const hour = time.getHours() + ''
                const minutes = time.getMinutes() + ''
                const seconds = time.getSeconds() + ''
                return year + '-' + this.addZero(month) + '-' + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minutes) + ':' + this.addZero(seconds)
            },

            // 将时间戳格式化日期字符串
            formatDate(numb, format) {
                //excel表中针对日期默认是1900年1月1日为基准
                const tempTime = new Date("1900-1-1").getTime()
                let timeDate = new Date(tempTime + (numb - 2.0) * 24 * 3600 * 1000 + 344000)
                // console.log('tempTime:%s,numb---,timeDate----%s,计算：%d', tempTime, numb, timeDate,(tempTime+(numb - 2.0) * 24 * 3600 * 1000));
                const year = timeDate.getFullYear() + ''
                const month = timeDate.getMonth() + 1 + ''
                const day = timeDate.getDate() - 0 + ''
                const hour = timeDate.getHours() + ''
                const minutes = timeDate.getMinutes() + ''
                const seconds = timeDate.getSeconds() + ''
                return year + format + this.addZero(month) + format + this.addZero(day) + ' ' + this.addZero(hour) + ':' + this.addZero(minutes) + ':' + this.addZero(seconds)
            },


            //   睡眠函数，numberMillis -- 要睡眠的毫秒数
            sleepFunction(numberMillis) {
                var now = new Date();
                var exitTime = now.getTime() + numberMillis;
                while (true) {
                    now = new Date();
                    if (now.getTime() > exitTime) {
                        return;
                    }
                }
            },

            // 处理支付方式分类
            handlePaymentMethodIdFuntion(payStr) {
                var payId = 0;
                for (let index = 0; index < this.paymentMethodList.length; index++) {
                    const tempDict = this.paymentMethodList[index];
                    if (tempDict.typeId == bookType.consumerId) {
                        payId = tempDict.typeId;
                        break;
                    }
                }
                return payId;
            },

            readExcel(e) {
                var that = this;
                const files = e.target.files;
                // 如果没有文件名
                if (files.length <= 0) {
                    return false;
                } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
                    this.$Message.error('上传格式不正确，请上传xls或者xlsx格式');
                    return false;
                }


                const fileReader = new FileReader();
                fileReader.onload = (ev) => {
                    try {
                        const data = ev.target.result;
                        // 切换为新的调用方式
                        const workbook = read(data, {
                            type: 'binary'
                        });
                        // 取第一张表
                        const wsname = workbook.SheetNames[0];

                        // 切换为新的调用方式 生成json表格内容
                        const wsArray = utils.sheet_to_json(workbook.Sheets[wsname]);
                        // console.log('wsArray-----', wsArray);

                        this.importDataNum = 0;
                        this.importDataTotal = wsArray.length;
                        if (this.importDataTotal > 0) {
                            let timerFunction = setInterval(() => {
                                if (this.importDataNum >= this.importDataTotal) {
                                    clearInterval(timerFunction);
                                    timerFunction = null;
                                    this.getArticleTypeList();
                                } else {
                                    let tempObject = wsArray[this.importDataNum];
                                    let tempStr = JSON.stringify(tempObject);
                                    let tempList = tempStr.split(',');

                                    var isUploadData = false;//是否上传数据
                                    var money = 0.0;//消费金额
                                    var usedTime = '';//消费时间、付款时间
                                    var remarks = '';//消费说明、描述、
                                    for (let index2 = 0; index2 < tempList.length; index2++) {
                                        let sheetStr = tempList[index2];
                                        // console.log('添加sheetStr---%s', sheetStr);
                                        if (sheetStr.indexOf('金额') >= 0) {
                                            let tempList2 = sheetStr.split(':');
                                            if (tempList2.length >= 2) {
                                                money = Number(tempList2[1]);
                                            }
                                        }

                                        // 交易创建时间
                                        if (sheetStr.indexOf('交易创建时间') >= 0) {
                                            let tempList2 = sheetStr.split(':');
                                            if (tempList2.length >= 2) {
                                                let moneyValue = Number(tempList2[1]);
                                                usedTime = this.formatDate(moneyValue, '-')
                                            }
                                        }

                                        if (sheetStr.indexOf('付款时间') >= 0 || sheetStr.indexOf('交易时间') >= 0) {
                                            let tempList2 = sheetStr.split(':');
                                            if (tempList2.length >= 2) {
                                                let moneyValue = parseFloat(tempList2[1]);
                                                console.log('时间moneyValue----%d,sheetStr---%s', moneyValue, sheetStr)
                                                if (!Number.isNaN(moneyValue)) {//判断是否数字类型
                                                    usedTime = this.formatDate(moneyValue, '-')
                                                }
                                            }
                                        }

                                        if (sheetStr.indexOf('交易来源地') >= 0) {
                                            let tempList2 = sheetStr.split(':');
                                            if (tempList2.length >= 2) {
                                                if (tempList2[1].indexOf('包括阿里巴巴和外部商家') == -1) {//没有匹配到
                                                    remarks = tempList2[1];//赋值操作
                                                }
                                            }
                                        }

                                        if (sheetStr.indexOf('交易对方') >= 0) {
                                            let tempList2 = sheetStr.split(':');
                                            if (tempList2.length >= 2) {
                                                if (remarks.length > 1) {
                                                    remarks = remarks + ',' + tempList2[1];//拼接
                                                } else {
                                                    remarks = remarks + '' + tempList2[1];//拼接
                                                }
                                            }
                                        }

                                        if (sheetStr.indexOf('商品名称') >= 0) {
                                            let tempList2 = sheetStr.split(':');
                                            if (tempList2.length >= 2) {
                                                let tempStr2 = tempList2[1];
                                                while (tempStr2.indexOf('\"') >= 0) {
                                                    tempStr2 = tempStr2.replace("\"", "")
                                                }
                                                if (remarks.indexOf(tempStr2) == -1) {
                                                    remarks = remarks + ',' + tempStr2;//拼接
                                                }
                                            }
                                        }

                                        if (sheetStr.indexOf('支付方式') >= 0) {
                                            let tempList2 = sheetStr.split(':');
                                            if (tempList2.length >= 2) {
                                                remarks = remarks + ',' + tempList2[1];//拼接
                                            }
                                        }

                                        //已支出----支付宝  
                                        if (sheetStr.indexOf('交易状态') >= 0) {
                                            if (sheetStr.indexOf('交易成功 ') >= 0 || sheetStr.indexOf('支付成功') >= 0) {
                                                isUploadData = true
                                            }
                                        }

                                        //已支出---微信
                                        if (sheetStr.indexOf('当前状态') >= 0) {
                                            if (sheetStr.indexOf('支付成功') >= 0 || sheetStr.indexOf('已转账') >= 0 || sheetStr.indexOf('对方已收钱') >= 0 || sheetStr.indexOf('已退款') >= 0) {
                                                isUploadData = true
                                            }
                                        }

                                        //支付平台 
                                        var payType = 0;
                                        if (sheetStr.indexOf('支付平台') >= 0) {
                                            if (sheetStr.indexOf('支付宝平台') >= 0) {
                                                payType = 1;
                                            } else if (sheetStr.indexOf('微信平台') >= 0) {
                                                payType = 2;
                                            }
                                        }

                                        while (remarks.indexOf(' ') >= 0) {
                                            remarks = remarks.replace(' ', '');
                                        }

                                        while (remarks.indexOf('\"') >= 0) {
                                            remarks = remarks.replace("\"", "")
                                        }
                                    }

                                    console.log('isUploadData---%d, money---%d,usedTime---%s,  remarks---%s,payType---%d', isUploadData, money, usedTime, remarks, payType);
                                    if (isUploadData) {
                                        this.batchAddBooksFunction(money, usedTime, remarks, payType);
                                    }
                                }
                                this.importDataNum++;
                            }, 100 * 3);
                            // 1000毫秒 = 1秒

                        }
                    } catch (e) {
                        return false;
                    }
                };
                fileReader.readAsBinaryString(files[0]);
            },


            // 获取消费记录列表, async代表异步操作
            async getArticleTypeList() {
                var dateState = 0;
                var usedTimeStart = '2000-01-01 00:00:00'
                var usedTimeEnd = '2100-01-01 23:59:59'
                // yyyy-MM-dd
                if (this.isNotEmptyStr(this.selectDate)) {
                    let dateList = this.selectDate.split('-')
                    if (this.dateRadio == '年') {
                        dateState = 1;
                        usedTimeStart = dateList[0] + "-01-01 00:00:00";
                        usedTimeEnd = dateList[0] + '-12-31 23:59:59'
                    } else if (this.dateRadio == '月') {
                        dateState = 2;
                        usedTimeStart = dateList[0] + "-" + dateList[1] + "-01 00:00:00";
                        let dayMax = this.getMonthLength(dateList[0], dateList[1]);
                        usedTimeEnd = dateList[0] + "-" + dateList[1] + "-" + dayMax + ' 23:59:59'
                    } else if (this.dateRadio == '日') {
                        dateState = 3;
                        usedTimeStart = dateList[0] + "-" + dateList[1] + "-" + dateList[2] + " 00:00:00";
                        usedTimeEnd = dateList[0] + "-" + dateList[1] + "-" + dateList[2] + ' 23:59:59'
                    }
                }

                const { data: res } = await this.$http.get('/accountBook/book/list', {
                    params: {
                        page: this.booksInformation.page,
                        typeId: this.booksInformation.typeId,
                        paymentMethodId: this.booksInformation.paymentMethodId,
                        businessId: this.booksInformation.businessId,
                        dateState: dateState,
                        usedTimeStart: usedTimeStart,
                        usedTimeEnd: usedTimeEnd,
                        token: window.sessionStorage.getItem('token'),
                    }
                });
                if (res.status >= 400 && res.status <= 410) {//用户权限问题，需要登录
                    this.$router.push('/login');
                    return this.$message.error(res.msg);
                } else {
                    if (res.status != 200) {
                        return this.$message.error(res.msg);
                    }
                }
                this.paymentMethodList = [];
                this.customList = [];
                this.businessList = [];
                this.typeList = [];
                this.allTypeList = [];
                for (let index = 0; index < res.typeList.length; index++) {
                    const tempDict = res.typeList[index];
                    if (tempDict.grate == 2) {
                        if (tempDict.parentName.includes("入口")) {//支付方式---之前
                            this.paymentMethodList.push(tempDict);
                        } else if (tempDict.parentName == "消费者") {
                            this.customList.push(tempDict);
                        } else if (tempDict.parentName == "商家名称") {
                            this.businessList.push(tempDict);
                        } else {
                            this.typeList.push(tempDict)
                            this.allTypeList.push(tempDict)
                        }
                    } else {
                        if (tempDict.parentName.includes("入口")) {
                            this.paymentMethodList.push(tempDict);
                        } else if (tempDict.parentName == "消费者") {

                        } else if (tempDict.parentName == "商家名称") {

                        } else {
                            this.allTypeList.push(tempDict)
                        }
                    }
                }
                this.articleTotal = res.totalNumber;
                this.articleTypeList = res.bookList;
                this.totalMoney = res.totalMoney;
            },


            // 处理消费分类
            handleBookTypeID(bookType) {
                var msgStr = '未知';
                for (let index = 0; index < this.typeList.length; index++) {
                    const tempDict = this.typeList[index];
                    if (tempDict.typeId == bookType.typeId) {
                        msgStr = tempDict.subName;
                        break;
                    }
                }
                return msgStr;
            },

            // 处理消费者分类
            handleBookConsumerId(bookType) {
                var msgStr = '未知';
                for (let index = 0; index < this.customList.length; index++) {
                    const tempDict = this.customList[index];
                    if (tempDict.typeId == bookType.consumerId) {
                        msgStr = tempDict.subName;
                        break;
                    }
                }
                return msgStr;
            },

            // 处理支付方式
            handleBookPaymentMethodId(bookType) {
                var msgStr = '未知';
                for (let index = 0; index < this.paymentMethodList.length; index++) {
                    const tempDict = this.paymentMethodList[index];
                    if (tempDict.typeId == bookType.paymentMethodId) {
                        msgStr = tempDict.subName;
                        break;
                    }
                }
                return msgStr;
            },

            // 处理商家名称
            handleBookBusinessId(bookType) {
                var msgStr = '未知';
                for (let index = 0; index < this.businessList.length; index++) {
                    const tempDict = this.businessList[index];
                    if (tempDict.typeId == bookType.businessId) {
                        msgStr = tempDict.subName;
                        break;
                    }
                }
                return msgStr;
            },

            // 处理主分类、支出、收入
            handleMainType(bookType) {
                return this.mainTypeList[bookType.mainType].name;
            },
            // 年月日切换
            changeDateFunction() {
                // console.log('value-----',value);
                // console.log('dateRadio-----', this.dateRadio);
                if (this.dateRadio == '年') {
                    this.selectDateType = 'year';
                    this.selectDatePlace = '请选择年份';
                    this.selectDateFormat = 'yyyy';
                } else if (this.dateRadio == '月') {
                    this.selectDateType = 'month';
                    this.selectDatePlace = '请选择月份';
                    this.selectDateFormat = 'yyyy-MM';
                } else if (this.dateRadio == '日') {
                    this.selectDateType = 'date';
                    this.selectDatePlace = '请选择日期';
                    this.selectDateFormat = 'yyyy-MM-dd';
                }
            },

            // 搜索功能
            searchBookTypeInformation() {
                // this.booksInformation.typeId = '-1';
                var grate = 0;
                var parentName = '未知';//主分类名称
                this.booksInformation.typeId = 0;
                for (let index = 0; index < this.allTypeList.length; index++) {
                    const tempDict = this.allTypeList[index];
                    if (tempDict.allName == this.bookTypeId) {
                        this.booksInformation.typeId = tempDict.typeId;
                        grate = tempDict.grate;
                        parentName = tempDict.parentName;
                        break;
                    }
                }

                // 支付方式
                this.booksInformation.paymentMethodId = 0;
                for (let index = 0; index < this.paymentMethodList.length; index++) {
                    const tempDict = this.paymentMethodList[index];
                    if (tempDict.allName == this.paymentMethodId) {
                        this.booksInformation.paymentMethodId = tempDict.typeId;
                        break;
                    }
                }


                // 商品名称
                this.booksInformation.businessId = 0;
                for (let index = 0; index < this.businessList.length; index++) {
                    const tempDict = this.businessList[index];
                    if (tempDict.allName == this.businessValue) {
                        this.booksInformation.businessId = tempDict.typeId;
                        break;
                    }
                }
                this.booksInformation.page = 1;
                this.getArticleTypeList();
            },

            // 重置搜索条件
            resetBookTypeInformation() {
                this.booksInformation.page = 1;
                this.booksInformation.typeId = 0;
                this.booksInformation.paymentMethodId = 0;
                this.booksInformation.businessId = 0;
                this.bookTypeId = '';
                this.paymentMethodId = '';
                this.businessValue = '';

                this.dateRadio = '日';
                this.selectDate = '';
                this.selectDateType = 'date';
                this.selectDatePlace = '选择日期';
                this.selectDateFormat = 'yyyy-MM-dd';
                this.getArticleTypeList();
            },

            // 监听pageSize改变事件
            handleSizeChange(newPage) {
                this.booksInformation.page = newPage;
                this.getArticleTypeList();
            },

            // 监听当前页码改变
            handleCurrentChange(newPage) {
                this.booksInformation.page = newPage;
                this.getArticleTypeList();
            },

            // 监听添加文章分类对话关闭事件
            addArticleTypeDialogClose() {
                this.filterString = '';
                this.filterTypeString = '';

                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addFormRef !== undefined) {
                    this.addFormRef.resetFields();
                }
            },

            // 点击新建分类按钮，添加文章分类信息
            addArticleType() {
                let usedTime = this.addForm.usedTime
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    //预校验通过,
                    const { data: res } = await this.$http.post('/accountBook/book/add', {
                        money: this.addForm.money,
                        typeId: this.addForm.typeId,
                        paymentMethodId: this.addForm.paymentMethodId,
                        businessId: this.addForm.businessId,
                        consumerId: this.addForm.consumerId,
                        usedTime: usedTime,
                        mainType: this.addForm.mainType,
                        remarks: this.addForm.remarks,
                        token: window.sessionStorage.getItem('token'),
                    });
                    // console.log(res.data);
                    // console.log('当前时间添加---222---', this.getCurrentDateTime());
                    if (res.status !== 200) {
                        return this.$message.error('添加消费记录失败');
                    }
                    this.$message.success('添加消费记录成功');
                    this.filterString = '';
                    this.filterTypeString = '';

                    // 隐藏添加的对话
                    this.addBookDialogVisible = false;

                    //重新获取平台列表
                    this.getArticleTypeList();
                })
            },

            // 根据时间来判断是早餐、午餐、晚餐
            mealJudgmentFunction(timeStr, startNum, endNum) {
                var isMeal = false;
                let tempList = timeStr.split(' ');
                if (tempList.length >= 2) {
                    let timeStr2 = tempList[1];
                    let tempList2 = timeStr2.split(':');
                    if (tempList2.length >= 1) {
                        let num = Number(tempList2[0]);
                        if (num >= startNum && num <= endNum) {
                            isMeal = true
                        }
                    }
                }
                // console.log('时间区间timeStr---%s,startNum---%d,endNum---%d,isMeal---%d', timeStr, startNum, endNum, isMeal)
                return isMeal;
            },


            // 批量添加消费记录信息  
            async batchAddBooksFunction(money, usedTime, remarks, paymentType) {
                //匹配消费分类
                var tempTypeId = 61;//默认消费类型为早餐，消费分类
                if (this.mealJudgmentFunction(usedTime, 11, 14)) {//11点到14点之间，默认午餐
                    tempTypeId = 62;
                } else if (this.mealJudgmentFunction(usedTime, 17, 20)) {//17点到20点之间，默认晚餐
                    tempTypeId = 63;
                }
                for (let index = 0; index < this.allTypeList.length; index++) {
                    const tempDict = this.allTypeList[index];
                    if (remarks.indexOf(tempDict.subName) >= 0) {
                        tempTypeId = tempDict.typeId;
                        break;
                    }
                }
                if (remarks.indexOf('手机充值') >= 0) {
                    tempTypeId = 82;
                }



                //匹配商家
                var tempBusinessId = 59;//默认为未知商户，商家分类
                for (let index = 0; index < this.businessList.length; index++) {
                    const tempDict = this.businessList[index];
                    if (remarks.indexOf(tempDict.subName) >= 0) {
                        tempBusinessId = tempDict.typeId;
                        break;
                    }
                }
                if (remarks.indexOf('武汉地铁') >= 0) {//武汉地铁
                    tempTypeId = 134;
                    tempBusinessId = 481;
                }
                if (remarks.indexOf('百佳生活超市') >= 0) {//百佳生活超市 
                    tempBusinessId = 132;
                }
                if (remarks.indexOf('蔡林记') >= 0) {//蔡林记 
                    tempBusinessId = 736;
                }
                if (remarks.indexOf('稻麦相承') >= 0) {//稻麦相承 
                    tempBusinessId = 149;
                }
                if (remarks.indexOf('滴滴出行') >= 0) {//滴滴出行 
                    tempTypeId = 70;
                    tempBusinessId = 138;
                }
                if (remarks.indexOf('云云餐饮店') >= 0) {//武汉东湖新技术开发区云云餐饮店，罐汤客山水年华店 
                    tempBusinessId = 417;
                }
                if (remarks.indexOf('哈啰出行') >= 0) {//哈啰出行 
                    tempTypeId = 135;
                    tempBusinessId = 175;
                }
                if (remarks.indexOf('好粥道') >= 0) {//好粥道  
                    tempTypeId = 61;
                    tempBusinessId = 121;
                }
                if (remarks.indexOf('米拉超市') >= 0) {//华容区米拉超市
                    tempTypeId = 73;
                    tempBusinessId = 737;
                }
                if (remarks.indexOf('全派餐厅') >= 0) {//全派餐厅，全派未来科技城餐厅
                    tempTypeId = 61;
                    tempBusinessId = 273;
                }
                if (remarks.indexOf('卤派二哥') >= 0) {//武汉东湖新技术开发区卤派二哥快餐店
                    tempTypeId = 62;
                    tempBusinessId = 117;
                }
                if (remarks.indexOf('鱼米江南') >= 0) {//鱼米江南武汉山水年华店 
                    tempTypeId = 62;
                    tempBusinessId = 60;
                }
                if (remarks.indexOf('优芙得') >= 0) {//武汉优芙得餐饮管理有限公司 
                    tempTypeId = 63;
                    tempBusinessId = 142;
                }
                if (remarks.indexOf('中百超市') >= 0) {//中百超市未来科技城店
                    tempBusinessId = 185;
                }
                if (remarks.indexOf('18271390665') >= 0) {//18271390665中国电信 
                    tempTypeId = 82;
                    tempBusinessId = 428;
                }
                if (remarks.indexOf('13638613115') >= 0) {//13638613115，中国移动 
                    tempTypeId = 82;
                    tempBusinessId = 158;
                }
                if (remarks.indexOf('兄弟餐馆') >= 0) {//鄂州市华容区兄弟餐馆
                    tempBusinessId = 738;
                }
                if (remarks.indexOf('铁路') >= 0) {//铁路12306，中国铁路网络有限公司 
                    tempTypeId = 160;
                    tempBusinessId = 184;
                }
                if (remarks.indexOf('南京灌汤') >= 0) {//武汉光谷未来城山水年华南京灌汤小笼包
                    tempBusinessId = 740;
                }
                if (remarks.indexOf('豪迈百货') >= 0) {//武汉光谷未来城山水年华特价批发超市（豪迈百货）  
                    tempBusinessId = 741;
                }
                if (remarks.indexOf('仙马生鲜') >= 0) {//武汉光谷未来城山水年华仙马生鲜蔬菜超市
                    tempBusinessId = 742;
                }
                if (remarks.indexOf('抖音小店') >= 0) {//抖音小店
                    tempBusinessId = 743;
                }
                if (remarks.indexOf('襄阳牛杂面') >= 0) {//襄阳牛杂面武汉山水年华店
                    tempBusinessId = 122;
                }
                if (remarks.indexOf('迎宾副食') >= 0) {//迎宾副食
                    tempBusinessId = 401;
                }

                if (remarks.indexOf('畅达公汽') >= 0) {//畅达公汽，鄂州公交
                    tempTypeId = 69;
                    tempBusinessId = 775;
                }
                if (remarks.indexOf('问止中医') >= 0) {//问止中医
                    tempTypeId = 777;
                    tempBusinessId = 776;
                }
                if (remarks.indexOf('鄂州新市民公汽') >= 0) {//畅达公汽，鄂州公交
                    tempTypeId = 69;
                    tempBusinessId = 778;
                }
                if (remarks.indexOf('东湖月老') >= 0) {//东湖月老相亲平台
                    tempTypeId = 681;
                    tempBusinessId = 471;
                }
                if (remarks.indexOf('恒泰购物广场') >= 0) {
                    tempTypeId = 68;
                    tempBusinessId = 153;
                }
                if (remarks.indexOf('红杏林') >= 0) {
                    tempTypeId = 101;
                    tempBusinessId = 781;
                }
                if (remarks.indexOf('湖北彩平方') >= 0) {
                    tempTypeId = 68;
                    tempBusinessId = 782;
                }
                if (remarks.indexOf('湖北非遗糕点文化园') >= 0) {
                    tempTypeId = 68;
                    tempBusinessId = 783;
                }
                if (remarks.indexOf('美团') >= 0) {
                    tempBusinessId = 191;
                }
                if (remarks.indexOf('奈雪') >= 0) {
                    tempTypeId = 66;
                    tempBusinessId = 421;
                }
                //匹配支付渠道
                var paymentMethodId = 19;//默认为其他入口
                if (paymentType == 1) {//支付宝平台
                    paymentMethodId = 56;//默认为招商银行信用卡8794
                    if (remarks.indexOf('支付宝') >= 0 || remarks.indexOf('淘宝') >= 0) {//余额宝的余额支付
                        paymentMethodId = 58;
                    } else if (remarks.indexOf('0939') >= 0) {//中信银行信用卡0939
                        paymentMethodId = 128;
                    } else if (remarks.indexOf('6214') >= 0) {//中信银行储蓄卡6214
                        paymentMethodId = 140;
                    } else if (remarks.indexOf('9009') >= 0) {//招商银行储蓄卡9009
                        paymentMethodId = 146;
                    } else if (remarks.indexOf('2215') >= 0) {//农业银行储蓄卡2215
                        paymentMethodId = 297;
                    } else if (remarks.indexOf('2676') >= 0) {//农业银行储蓄卡2676
                        paymentMethodId = 298;
                    } else if (remarks.indexOf('1460') >= 0) {//中国工商银行卡1460
                        paymentMethodId = 649;
                    } else if (remarks.indexOf('9707') >= 0) {//招商银行信用卡9707
                        paymentMethodId = 650;
                    } else if (remarks.indexOf('1027') >= 0) {//中国工商银行卡1027
                        paymentMethodId = 651;
                    }
                    if (remarks.indexOf('蒸福包子') >= 0) {//蒸福包子
                        tempBusinessId = 124;
                        paymentMethodId = 58;
                    }
                    if (remarks.indexOf('酱香饼') >= 0) {//武汉未来科技城山水年华酱香饼
                        tempBusinessId = 739;
                        paymentMethodId = 58;
                    }
                } else if (paymentType == 2) {//微信平台
                    paymentMethodId = 665;//默认为招商银行信用卡8794
                    if (remarks.indexOf('零钱') >= 0) {//微信零花钱
                        paymentMethodId = 653;
                    } else if (remarks.indexOf('0939') >= 0) {//中信银行信用卡0939
                        paymentMethodId = 684;
                    } else if (remarks.indexOf('9009') >= 0) {//招商银行储蓄卡9009
                        paymentMethodId = 676;
                    } else if (remarks.indexOf('1460') >= 0) {//中国工商银行卡1460
                        paymentMethodId = 663;
                    } else if (remarks.indexOf('9707') >= 0) {//招商银行信用卡9707
                        paymentMethodId = 667;
                    }

                    if (remarks.indexOf('蒸福包子') >= 0) {//蒸福包子
                        tempBusinessId = 124;
                        paymentMethodId = 653;
                    }
                    if (remarks.indexOf('酱香饼') >= 0) {//武汉未来科技城山水年华酱香饼
                        tempBusinessId = 739;
                        paymentMethodId = 653;
                    }
                }

                //消费者分类
                var consumerId = 53;   //默认为消费者飞哥
                if (remarks.indexOf('曹立令') >= 0) {
                    consumerId = 136;
                    tempBusinessId = 784;
                }
                if (remarks.indexOf('温成娇') >= 0) {
                    consumerId = 779;
                    tempBusinessId = 785;
                }

                const { data: res } = await this.$http.post('/accountBook/book/add', {
                    money: money,
                    typeId: tempTypeId, //默认消费类型为早餐
                    paymentMethodId: paymentMethodId,  //默认为招商银行信用卡8794
                    businessId: tempBusinessId,
                    consumerId: consumerId,
                    usedTime: usedTime,
                    mainType: '1',    //默认为支出类型
                    remarks: remarks,
                    token: window.sessionStorage.getItem('token'),
                });
                if (this.importDataNum > 0 && this.importDataTotal <= this.importDataNum) {
                    this.getArticleTypeList();
                }
            },

            //点击编辑的修改文章分类弹窗
            showArticleTypeEditDialog(row) {
                // console.log('编辑的修改平台弹窗');
                // console.log(JSON.parse(JSON.stringify(row)));
                this.editBookDialogVisible = true;
                this.editForm = JSON.parse(JSON.stringify(row));
                if (this.editForm.grate == 1) {
                    this.editParentVisible = true;
                } else {
                    this.editParentVisible = false;
                }
            },

            //修改平台弹窗关闭
            editUserDialogClose() {
                this.filterString = '';
                this.filterTypeString = '';
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.editForm !== undefined) {
                    this.editForm.resetFields();
                }
            },

            //修改消费记录信息，提交
            editBookInfor() {
                let usedTime = this.editForm.usedTime;
                this.$refs.editFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    //预校验通过,
                    const { data: res } = await this.$http.post('/accountBook/book/edit', {
                        bookId: this.editForm.bookId,
                        money: this.editForm.money,
                        typeId: this.editForm.typeId,
                        paymentMethodId: this.editForm.paymentMethodId,
                        businessId: this.editForm.businessId,
                        consumerId: this.editForm.consumerId,
                        usedTime: usedTime,
                        mainType: this.editForm.mainType,
                        remarks: this.editForm.remarks,
                        token: window.sessionStorage.getItem('token'),
                    });
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    this.$message.success('修改消费记录成功');
                    // 隐藏修改平台的对话
                    this.editBookDialogVisible = false;
                    this.filterString = '';
                    this.filterTypeString = '';

                    // 重新获取平台列表
                    this.getArticleTypeList();
                })
            },


            // 监听 switch 开关状态的改变
            async bookTypeStateChanged(bookModel) {
                var msgStr = '此操作将打开该分类显示,是否继续?'
                if (bookModel.isDeleted) {
                    msgStr = '此操作将关闭该分类显示, 是否继续?'
                }

                // 询问是否真的删除当前
                const confirmResult = await this.$confirm(msgStr, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => {
                    return err;
                });

                // 如果标签名称，确认删除，则返回值为字符串 confirm 
                // 如果标签名称，取消删除，则返回值为字符串 cancel 
                // console.log(confirmResult);
                if (confirmResult !== 'confirm') {
                    bookModel.isDeleted = !bookModel.isDeleted;
                    return this.$message.info('已取消当前操作');
                }
                //预校验通过,
                const { data: res } = await this.$http.post('/accountBook/book/delete', {
                    bookId: bookModel.bookId,
                    isDeleted: bookModel.isDeleted,
                    token: window.sessionStorage.getItem('token'),
                });
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('设置成功');
            },

        },

    }
</script>


<!-- 样式 -->
<style lang="less" scoped>
    /* 搜索总样式 */
    .searchBooksTotalStyle {
        margin-top: 10px;
    }

    /* 新建消费记录 */
    .addBookStyle {
        width: 90px;
    }

    /* 输入 */
    .outputListUpload {
        margin-left: 10px;
    }

    /* 消费分类选择的样式 */
    .bookTypeStyle {
        padding-left: 1px !important;
        width: 170px;
    }

    /* 支付方式的样式 */
    .paymentMethodStyle {
        margin-left: 5px;
        padding-left: 5px;
        width: 220px;
    }

    /* 商铺分类 */
    .businessStyle {
        margin-left: 10px;
        width: 180px;
    }

    /* 日、月、年的切换样式 */
    .dateGroupStyle {
        margin-left: 10px;
    }

    /* 选择日期的样式 */
    .dateSelecttyle {
        margin-left: 5px;
    }

    /* 搜索对应的时间选择框 */
    .dateSelecttyle /deep/ .el-input__inner,
    .el-input {
        width: 140px !important;
    }

    /* 搜索的样式 */
    .searchBookTypeStyle {
        margin-left: 10px;
        width: 65px;
    }

    /* 重置的样式 */
    .resetBookTypeStyle {
        margin-left: 10px;
        width: 65px;
    }

    /*  使用deep样式穿透 面包屑导航里面字体样式 */
    .bookClassNavi /deep/ .el-breadcrumb {
        font-size: 20px !important;
    }

    /* el-form-item表单格式 */
    .addBookFormClass /deep/ .el-form-item {
        /* 与下面的间距 */
        margin-bottom: 15px !important;
    }

    /* 多选框 */
    /* .addBookFormClass /deep/ .el-form-item .el-form-item__content .el-select {
        width: 400px !important;
    } */

    /* 时间选择框 */
    /* .addBookFormClass /deep/ .el-form-item .el-form-item__content .el-date-editor {
        width: 400px !important;
    } */

    /* 搜索对应的时间选择框 */
    /* .addBookFormClass /deep/ .el-form-item .el-form-item__content .el-input {
        width: 400px !important;
    } */

    .businessIdTypeStyle {
        width: 280px !important;
    }

    .filterStringTypeStyle {
        margin-left: 10px;
        width: 200px !important;
    }

    .businessRemarksTypeStyle {
        width: 500px !important;
    }
</style>