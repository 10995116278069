<template>
    <div class='articleDetailHeader'>
        <div class="articleDetailLocation">
            <ul>
                <li class="articleDetailLocationTitle">当前位置：</li>
                <li class="articleDetailLocationTitleNavi">
                    <!-- 面包屑导航 -->
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item class="articleDetailLocationTitle-Navi" :to="{path: '/home'}">首页
                        </el-breadcrumb-item>
                        <el-breadcrumb-item class="articleDetailLocationTitle-Navi" :to="activePath">
                            {{this.articleType}}</el-breadcrumb-item>
                        <el-breadcrumb-item>详情页面</el-breadcrumb-item>
                    </el-breadcrumb>
                </li>
            </ul>
        </div>

        <div class="articleDetailTitle">
            <h1>{{this.articleTitle}}</h1>
        </div>

        <div class="articleDetailTime">
            <span>发布时间: {{handleCreatDate(this.createdTime)}}</span>
            <span>分类: {{this.articleType}}</span>
            <span>标签: {{this.articleTag}}</span>
            <span>浏览量: {{this.articleViews}}</span>
            <span>权限等级: {{this.articleGrade}}</span>
        </div>

        <!-- 直接加载html -->
        <div class="articleDetailContent">
            <!-- 编辑器 -->
            <Editor class="editorClass" v-model="html2" :defaultConfig="editorConfig" :mode="mode"
                @onCreated="onCreated" />
        </div>

        <div class="articleDetailNext">
            <el-button type="text" class="article_title_button" @click='seeArticleDetailLast'
                v-show="article_show_Last">
                上一篇：{{this.articleDictLast.articleTitle}}</el-button>
        </div>
        <div class="articleDetailNext">
            <el-button type="text" class="article_title_button" @click='seeArticleDetailNext'
                v-show="article_show_Next">
                下一篇：{{this.articleDictNext.articleTitle}}</el-button>
        </div>

    </div>
</template>



<script>
    import '@wangeditor/editor/dist/css/style.css'
    import { createEditor } from '@wangeditor/editor'
    import { Editor } from '@wangeditor/editor-for-vue'

    export default {
        components: { Editor },

        // SEO化，动态设置, 
        metaInfo() {
            return {
                title: this.headTitle,
                meta: [{
                    charset: "utf-8"
                },
                {
                    name: 'keyWords',
                    content: this.headKeyWord,
                },
                {
                    name: 'description',
                    content: this.headDescription,
                }]
            }
        },

        data() {
            return {
                editor: null, //实例
                html2: '',  //html格式
                editorConfig: { placeholder: '......' }, //编译器配置
                mode: 'default',        // or 'simple'，  样式

                articleId: 0,           //文章ID
                articleTitle: '',       //文章标题
                articleContent: '',     //文章内容
                articleType: '',        //文章分类
                articleTag: '',         //文章标签
                articleViews: 0,        //文章浏览量
                articleGrade: 0,        //文章权限等级
                createdTime: '',        //文章创建时间
                updatedTime: '',        //文章最近修改时间
                activePath: '',         //默认菜单栏的地址
                articleViewsValid: 0,   //有效浏览量判断参数，大于0，代表有效浏览了

                articleDictLast: {},          //上一篇文章字典信息
                article_show_Last: true,     //是否显示，默认显示---上一篇

                articleDictNext: {},          //下一篇文章字典信息
                article_show_Next: true,     //是否显示，默认显示---下一篇


                // SEO化
                headTitle: '飞哥个人博客',
                headKeyWord: '个人博客, 个人网站',
                headDescription: '飞哥， 个人博客, 个人网站，python, Qt',
            }
        },


        //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
        created() {
            console.log('详情页面-------created');
            this.articleId = this.$route.query.articleId;   // 透传过来的文章ID
            this.activePath = window.sessionStorage.getItem('activePath');
            console.log("articleId-----%s", this.articleId);
            this.timeIntervalFunction();
            this.getArticleDetail();
        },

        // 方法
        methods: {
            // 时间间隔函数
            timeIntervalFunction() {
                const articleIdTime = window.sessionStorage.getItem('articleId' + this.articleId);
                //获取当前时间戳，单位为秒；
                const currentTime = Date.parse(new Date()) / 1000;
                console.log("时间差-----%d", currentTime - articleIdTime);
                // 代表浏览同一篇文章在1分钟内按照一次计算，1分钟为60秒，1小时为3600秒
                if (currentTime - articleIdTime > 3600) {
                    window.sessionStorage.setItem('articleId' + this.articleId, currentTime);
                    this.articleViewsValid = 1;
                }
            },

            // 编辑器在创建的时候
            onCreated(editor) {
                console.log('onCreated------', editor)
                this.editor = Object.seal(editor);  // 一定要用 Object.seal() ，否则会报错
                this.editor.disable();  //设置不可编辑状态
            },

            // 获取文章分类列表, async代表异步操作
            async getArticleDetail() {
                const { data: res } = await this.$http.get('/article/article/detail', {
                    params: {
                        articleId: this.articleId,
                        articleViewsValid: this.articleViewsValid,
                        token: window.sessionStorage.getItem('token'),
                    }
                });
                if (res.status !== 200) {
                    this.$router.push('/login');
                    return this.$message.error(res.msg);
                }
                console.log(res);
                this.articleId = res.article.articleId;
                this.articleTitle = res.article.articleTitle;
                this.articleContent = res.article.articleContent;
                this.articleType = res.article.articleType;
                this.articleTag = res.article.articleTag;
                this.articleViews = res.article.articleViews;
                this.articleGrade = res.article.articleGrade;
                this.createdTime = res.article.createdTime;

                // SEO
                this.headTitle = res.article.articleTitle;
                this.headKeyWord = res.article.articleTag;
                this.headDescription = res.article.articleIntroduction;

                // 上一篇文章信息
                this.articleDictLast = res.articleLast;
                if (this.articleDictLast.articleId > 0) {
                    this.article_show_Last = true;
                } else {
                    this.article_show_Last = false;
                }

                // 下一篇文章信息
                this.articleDictNext = res.articleNext;
                if (this.articleDictNext.articleId > 0) {
                    this.article_show_Next = true;
                } else {
                    this.article_show_Next = false;
                }

                // console.log('html2-------cont------');
                // // JSON.parse目的是将字符串转换为对象，与JSON.stringify是一对
                // let cont = JSON.parse(res.article.articleContent);
                // console.log(cont);
                // const editorNew = createEditor({ content: cont })
                // this.html2 =  editorNew.getHtml();
                this.html2 = res.article.articleContent;
            },

            // 处理文章日期进行处理
            handleCreatDate: function (dateStr) {
                let contentArr = dateStr;
                if (dateStr.length > 10) {
                    contentArr = dateStr.slice(0, 10);
                }
                return contentArr;
            },

            // 查看上一篇文章
            seeArticleDetailLast() {
                this.articleId = this.articleDictLast.articleId;
                // console.log('articleDictLast------', this.articleDictLast);
                let navigateItemStr = window.sessionStorage.getItem('navigateItem');
                let navigateItemNew = JSON.parse(navigateItemStr);
                navigateItemNew.forEach((naviItem) => {
                    naviItem.items.forEach((naviItem2) => {
                        if (naviItem2.key == this.articleDictLast.articleTypeId) {
                            window.sessionStorage.setItem('activePath', '/' + naviItem2.path);
                            this.activePath = window.sessionStorage.getItem('activePath');
                        }
                    });
                });

                window.sessionStorage.setItem('articleId', this.articleId);
                window.sessionStorage.setItem('articleTypeId', this.articleDictLast.articleTypeId);
                window.sessionStorage.setItem('typeTitle', this.articleDictLast.articleTypeDetail);
                let newQuery = JSON.parse(JSON.stringify(this.$route.query));
                newQuery.articleId = this.articleId;
                this.$router.replace({
                    query: newQuery
                })
                this.timeIntervalFunction();
                this.getArticleDetail();
            },

            // 下一篇文章
            seeArticleDetailNext() {
                this.articleId = this.articleDictNext.articleId;
                let navigateItemStr = window.sessionStorage.getItem('navigateItem');
                let navigateItemNew = JSON.parse(navigateItemStr);
                navigateItemNew.forEach((naviItem) => {
                    naviItem.items.forEach((naviItem2) => {
                        if (naviItem2.key == this.articleDictNext.articleTypeId) {
                            window.sessionStorage.setItem('activePath', '/' + naviItem2.path);
                            this.activePath = window.sessionStorage.getItem('activePath');
                        }
                    });
                });

                window.sessionStorage.setItem('articleId', this.articleId);
                window.sessionStorage.setItem('articleTypeId', this.articleDictNext.articleTypeId);
                window.sessionStorage.setItem('typeTitle', this.articleDictNext.articleTypeDetail);
                let newQuery = JSON.parse(JSON.stringify(this.$route.query));
                newQuery.articleId = this.articleId;
                this.$router.replace({
                    query: newQuery
                })
                this.timeIntervalFunction();
                this.getArticleDetail();
            },
        },

    }
</script>




<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
    /* 头部 */
    .articleDetailHeader {
        /* 外边距 */
        margin: 0px;
        /* 内边距 */
        padding: 0px;
        /*  背景颜色 */
        background-color: rgb(255, 255, 255);
        /* 字体颜色 */
        color: black;
        width: 100%;
        /* height: 100%; */
        /* height: 1500px; */
        /* 设置圆角 */
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        /* 
        flex-direction:
        1、row（默认值）：主轴为水平方向，起点在左端。
        2、row-reverse：主轴为水平方向，起点在右端。
        3、column：主轴为垂直方向，起点在上沿。
        4、column-reverse：主轴为垂直方向，起点在下沿。
         */
        flex-direction: column;
    }

    /* 位置信息---面包屑导航 */
    .articleDetailLocation {
        display: block;
        /* 水平布局 */
        float: left;
        /* white-space: nowrap;
        overflow: hidden; */
        background-color: #eaeaea;
        margin-bottom: 10px;
    }

    /* 当前位置：-----样式 */
    .articleDetailLocationTitle {
        /* 水平布局 */
        float: left;
        display: block;
        /* width: 100px; */
        height: 30px;
        justify-content: left;
        text-align: left;
        margin-left: 10px;
        margin-top: 10px;
        font-size: 18px;
    }

    /* 面包屑导航----样式 */
    .articleDetailLocationTitleNavi {
        /* 水平布局 */
        float: left;
        display: block;
        width: 300px;
        height: 30px;
        padding-top: 15px;
    }

    /* 使用deep样式穿透 面包屑导航里面字体样式 */
    .articleDetailLocationTitleNavi /deep/ .el-breadcrumb {
        font-size: 16px !important;
    }

    /* 当前位置下面的箭头样式 */
    .articleDetailLocationTitleNavi /deep/ .el-breadcrumb__separator {
        color: black !important;
    }

    .articleDetailLocationTitle-Navi /deep/ .el-breadcrumb__inner:hover {
        color: #00c1de !important;
    }

    /* 标题样式 */
    .articleDetailTitle {
        height: 24px;
        /* 文本对齐 */
        text-align: left;
        margin-left: 10px;
        margin-top: 1px;
        text-align: center;
    }

    /* 时间样式 */
    .articleDetailTime {
        height: 20px;
        font-size: 14px;
        margin-left: 10px;
        margin-top: 40px;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: #dbdbdb 1px dashed;
    }

    .articleDetailTime span {
        width: 300px;
        padding-right: 20px;
    }

    .articleDetailContent {
        font-size: 20px;
        margin: 10px;
    }

    .p,
    li,
    td,
    th,
    blockquote {
        white-space: pre-wrap;

    }


    /* 表格 */
    .table {
        border-collapse: collapse;
    }

    .table th,
    table td {
        border: 1px solid #ccc;
        min-width: 50px;
        height: 20px;
        text-align: left;
    }

    .table th {
        background-color: #f1f1f1;
        text-align: center
    }

    /* 代码块 */
    pre>code {
        display: block;
        border: 1px solid hsl(0, 0%, 91%);
        border-radius: 4px 4px;
        text-indent: 0;
        background-color: #fafafa;
        padding: 10px;
        font-size: 14px;
    }

    /* 引用 */
    blockquote {
        display: block;
        border-left: 8px solid #d0e5f2;
        padding: 10px 10px;
        margin: 10px 0;
        background-color: #f1f1f1;
    }

    /* 列表 */
    .ul,
    ol {
        margin: 10px 0 10px 20px;
    }

    /* 分割线 */
    .hr {
        display: block;
        width: 90%;
        margin: 20px auto;
        border: 0;
        height: 1px;
        background-color: #ccc;
    }



    /* 右侧栏---标题 */
    .article_title_button {
        /* 左侧距离 */
        margin-left: 10px;
        margin-bottom: 5px;
        font-size: 16px !important;
        height: 25px !important;
        padding: 5px 0px !important;
    }

    /* 上下篇---按钮 样式*/
    .articleDetailNext /deep/ .el-button--text {
        color: black !important;
    }

    /* 上下篇---按钮 样式----激活状态   00c1de*/
    .articleDetailNext /deep/ .el-button--text:active {
        color: #00c1de !important;
    }

    /* 上下篇---按钮 样式----悬停状态*/
    .articleDetailNext /deep/ .el-button--text:hover {
        color: #00c1de !important;
    }
</style>