<template>
    <!-- 消费分类 -->
    <div>
        <div class="bookTypeClassNavi">
            <!-- 面包屑导航---- -->
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/welcome' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>个人账本</el-breadcrumb-item>
                <el-breadcrumb-item>消费分类</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 卡片视图区域  -->
        <el-card class="box-card">
            <el-row>
                <el-button class="addBookStyle" type="primary" @click='addBookTypeDialogVisible = true'>
                    新建分类
                </el-button>

                <el-select class="bookTypeStyle" v-model="bookTypeId" placeholder="请选择消费分类">
                    <!-- 外层for循环，里面el-option采用v-if条件判断 -->
                    <template>
                        <div v-for="(item, index) in bookTypeParentList" :key="index">
                            <!-- 判断是否父级类 -->
                            <el-option v-if="item.grate == 1" :label="item.parentName" :value="item.parentName">
                            </el-option>
                        </div>
                    </template>
                </el-select>

                <el-input class="filterStringTypeStyle" v-model="filterString" placeholder="请输入过滤的字符串"></el-input>

                <el-button class="searchBookTypeStyle" type="primary" @click='searchBookTypeInformation'>
                    搜索
                </el-button>

                <el-button class="resetBookTypeStyle" type="primary" @click='resetBookTypeInformation'>
                    重置
                </el-button>
            </el-row>

            <!-- 平台列表  ：data绑定数据源，prop指定参数对应的值， border边框，stripe隔行变色-->
            <el-table :data="bookeTypeList" border stripe style="width: 100%"
                :header-cell-style="{'text-align':'center'}">
                <el-table-column prop="typeId" label="分类ID" width="70" align="center">
                </el-table-column>
                <el-table-column prop="grate" label="分类等级" width="80" align="center">
                </el-table-column>
                <el-table-column prop="customID" label="自定义ID" width="80" align="center">
                </el-table-column>
                <el-table-column prop="parentName" label="父类名称" width="150" align="center">
                </el-table-column>
                <el-table-column prop="subName" label="子类名称" width="180" align="center">
                </el-table-column>
                <el-table-column prop="mainType" label="收/支" width="70" align="center">
                    <template slot-scope="scope">
                        <span>
                            {{handleMainType(scope.row)}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" width="180" align="center">
                </el-table-column>
                <el-table-column label="是否删除" width="80">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.isDeleted" @change="bookTypeStateChanged(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column label="操作">
                    <!-- 作用域插槽的使用 -->
                    <template slot-scope="scope">
                        <!-- enterablez 鼠标是否可进入到 tooltip 中 自动隐藏-->
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <!-- 编辑按钮 -->
                            <el-button type="primary" icon="el-icon-edit" @click="showBookTypeEditDialog(scope.row)">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 页码 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="bookTypeInformation.page" :page-size="pageSize" layout="total, prev, pager, next, jumper"
                :total="bookTypeTotal" class="articlePage-list">
            </el-pagination>



            <!-- 添加文章分类的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="添加分类" :visible.sync="addBookTypeDialogVisible" width="50%"
                @close="addBookTypeDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <!-- prop验证规则属性 v-model双向绑定数据-->
                    <el-form-item label="父级" prop="parentName">
                        <el-select v-model="addForm.parentName" placeholder="请选择父级">
                            <el-option label="顶级" value="顶级"></el-option>
                            <!-- 外层for循环，里面el-option采用v-if条件判断 -->
                            <template>
                                <div v-for="(item, index) in bookTypeParentList" :key="index">
                                    <!-- 判断是否父级类 -->
                                    <el-option v-if="item.grate == 1 " :label="item.parentName"
                                        :value="item.parentName"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分类名称" prop="subName">
                        <el-input v-model="addForm.subName"></el-input>
                    </el-form-item>
                    <el-form-item label="支出/收入" prop="mainType">
                        <el-select v-model="addForm.mainType" placeholder="请选择类型">
                            <template>
                                <div v-for="(item, index) in mainTypeList" :key="index">
                                    <el-option :label="item.name" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分类介绍" prop="content">
                        <el-input v-model="addForm.content"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer">
                    <el-button @click="addBookTypeDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addBookType">确 定</el-button>
                </div>
            </el-dialog>



            <!-- 修改文章分类的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="修改分类信息" :visible.sync="editBookTypeDialogVisible" width="50%"
                @close="editBookTypeDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="父级" prop="parentName">
                        <el-input v-model="editForm.parentName" :disabled="editParentVisible"></el-input>
                    </el-form-item>
                    <el-form-item label="分类名称" prop="subName">
                        <el-input v-model="editForm.subName"></el-input>
                    </el-form-item>
                    <el-form-item label="支出/收入" prop="mainType">
                        <el-select v-model="editForm.mainType" placeholder="请选择类型">
                            <template>
                                <div v-for="(item, index) in mainTypeList" :key="index">
                                    <el-option :label="item.name" :value="item.typeId"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分类介绍" prop="content">
                        <el-input v-model="editForm.content"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer">
                    <el-button @click="editBookTypeDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editBookTypeInformation">确 定</el-button>
                </div>
            </el-dialog>
        </el-card>

    </div>
</template>




<!-- 行为 -->
<script>
    export default {
        // 数据处理
        data() {
            return {
                //获取消费等分类列表的所需要设置的参数
                bookTypeInformation: {
                    page: 1, //消费分类列表页码数，默认每页5条数据
                    typeId: '-1', //消费分类，未知状态
                },

                bookTypeTotal: 0,  //分类总数
                pageSize: 5, //默认每页5条数据

                // 搜索功能
                bookTypeId: '',         //消费分类
                filterString: '',        //过滤的字符串

                //分类列表---每页最多5条数据
                bookeTypeList: [],

                //所有分类列表
                bookTypeParentList: [],

                //主分类列表，0是未知，1为支出性，2为收入性
                mainTypeList: [{ "typeId": 0, "name": "未知" }, { "typeId": 1, "name": "支出" }, { "typeId": 2, "name": "收入" }],

                // 控制添加文章分类对话框的显示与隐藏
                addBookTypeDialogVisible: false,

                //添加文章分类的表单数据对象
                addForm: {
                    parentName: '', //父类
                    subName: '',    //分类名称---有可能是父类名称，也有可能是子类名称
                    content: '',    //说明，介绍
                    mainType: '',    //未知0，支出1、收入2
                },

                // 添加文章分类表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addFormRules: {
                    parentName: [
                        { required: true, message: '请选择父级', trigger: 'blur' }
                    ],
                    subName: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '分类名称长度在1到15个字符', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请输入分类介绍', trigger: 'blur' },
                    ],
                    mainType: [
                        { required: true, message: '请选择父级', trigger: 'blur' }
                    ],
                },

                // 控制修改分类对话框的显示与隐藏
                editBookTypeDialogVisible: false,

                //父类（一级）是否顶级，如果是true, 否则是false,
                editParentVisible: false,

                //获取到修改平台的数据对象
                editForm: {},

                // 修改平台表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                editFormRules: {
                    parentName: [
                        { required: true, message: '请输入父级', trigger: 'blur' },
                        { min: 2, max: 15, message: '父级长度在2到15个字符', trigger: 'blur' }
                    ],
                    subName: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '分类名称长度在2到15个字符', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请输入分类介绍', trigger: 'blur' },
                    ],
                },
            }
        },

        //创建
        created() {
            this.getBookTypeList();
        },

        // 方法
        methods: {
            // 获取分类列表, async代表异步操作
            async getBookTypeList() {
                // article
                const { data: res } = await this.$http.get('/accountBook/type/list', {
                    params: {
                        page: this.bookTypeInformation.page,
                        typeId: this.bookTypeInformation.typeId,
                        filterString: this.filterString,
                        token: window.sessionStorage.getItem('token'),
                    }
                });
                if (res.status >= 400 && res.status <= 410) {//用户权限问题，需要登录
                    this.$router.push('/login');
                    return this.$message.error(res.msg);
                } else {
                    if (res.status != 200) {
                        this.$message.error(res.msg);
                    }
                }
                this.bookeTypeList = res.typeList;
                this.bookTypeParentList = res.typeParentList;
                this.bookTypeTotal = res.totalNumber;
            },

            // 搜索功能
            searchBookTypeInformation() {
                console.log('------', this.bookTypeId);
                this.bookTypeInformation.typeId = '-1';
                this.bookTypeInformation.page = 1;
                for (let index = 0; index < this.bookTypeParentList.length; index++) {
                    const tempDict = this.bookTypeParentList[index];
                    if (tempDict.parentName == this.bookTypeId) {
                        if (tempDict.grate == 1) {
                            this.bookTypeInformation.typeId = tempDict.typeId;
                        } else {
                            this.bookTypeInformation.typeId = this.bookTypeInformation.typeId + '_' + tempDict.typeId;
                        }
                    }
                }
                this.getBookTypeList();
            },

            // 重置搜索条件
            resetBookTypeInformation() {
                this.bookTypeInformation.page = 1;
                this.bookTypeInformation.typeId = '-1';
                this.bookTypeId = '';
                this.filterString = '';
                this.getBookTypeList();
            },

            // 处理主分类、支出、收入
            handleMainType(bookType) {
                return this.mainTypeList[bookType.mainType].name;
            },

            // 监听pageSize改变事件
            handleSizeChange(newPage) {
                console.log('handleSizeChange');
                this.bookTypeInformation.page = newPage;
                this.getBookTypeList();
            },

            // 监听当前页码改变
            handleCurrentChange(newPage) {
                console.log('handleCurrentChange');
                this.bookTypeInformation.page = newPage;
                this.getBookTypeList();
            },

            // 监听添加分类对话关闭事件
            addBookTypeDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addFormRef !== undefined) {
                    this.addFormRef.resetFields();
                }
            },

            // 点击新建分类按钮，添加文章分类信息
            addBookType() {
                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    //预校验通过,
                    const { data: res } = await this.$http.post('/accountBook/type/add', {
                        parentName: this.addForm.parentName,
                        subName: this.addForm.subName,
                        content: this.addForm.content,
                        mainType: this.addForm.mainType,
                        token: window.sessionStorage.getItem('token'),
                    });

                    if (res.status !== 200) {
                        return this.$message.error('新建支出/收入的分类失败');
                    }
                    this.$message.success('新建支出/收入的分类成功');

                    // 隐藏添加的对话
                    this.addBookTypeDialogVisible = false;

                    //重新获取平台列表
                    this.getBookTypeList();
                })
            },

            //点击编辑的修改分类弹窗
            showBookTypeEditDialog(row) {
                console.log(JSON.parse(JSON.stringify(row)));
                this.editBookTypeDialogVisible = true;
                this.editForm = JSON.parse(JSON.stringify(row));
                console.log(this.editForm.grate);
                if (this.editForm.grate == 1) {
                    this.editParentVisible = true;
                } else {
                    this.editParentVisible = false;
                }
            },

            //修改平台弹窗关闭
            editBookTypeDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.editFormRules !== undefined) {
                    this.editFormRules.resetFields();
                }
            },

            //修改分类信息，提交
            editBookTypeInformation() {
                this.$refs.editFormRef.validate(async valid => {
                    console.log(valid);
                    if (!valid) {//预校验不通过
                        console.log('预校验不通过');
                        return;
                    }
                    //预校验通过,
                    const { data: res } = await this.$http.post('/accountBook/type/edit', {
                        typeId: this.editForm.typeId,
                        parentName: this.editForm.parentName,
                        subName: this.editForm.subName,
                        content: this.editForm.content,
                        mainType: this.editForm.mainType,
                        token: window.sessionStorage.getItem('token'),
                    });
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    this.$message.success('修改分类成功');
                    // 隐藏修改平台的对话
                    this.editBookTypeDialogVisible = false;

                    // 重新获取分类列表
                    this.getBookTypeList();
                })
            },


            // 监听 switch 开关状态的改变
            async bookTypeStateChanged(bookType) {
                console.log('开关状态的改变-------')
                var msgStr = '此操作将打开该分类显示,是否继续?'
                if (bookType.isDeleted) {
                    msgStr = '此操作将关闭该分类显示, 是否继续?'
                }

                // 询问是否真的删除当前
                const confirmResult = await this.$confirm(msgStr, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => {
                    return err;
                });

                // 如果标签名称，确认删除，则返回值为字符串 confirm 
                // 如果标签名称，取消删除，则返回值为字符串 cancel 
                console.log(confirmResult);
                if (confirmResult !== 'confirm') {
                    bookType.isDeleted = !bookType.isDeleted;
                    return this.$message.info('已取消当前操作');
                }
                //预校验通过,
                const { data: res } = await this.$http.post('/accountBook/type/delete', {
                    typeId: bookType.typeId,
                    isDeleted: bookType.isDeleted,
                    token: window.sessionStorage.getItem('token'),
                });
                console.log(res.data);
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('设置成功');
            },

        },

    }
</script>


<!-- 样式 -->
<style lang="less" scoped>
    .addBookStyle {
        width: 90px;
    }

    /* 消费分类选择的样式 */
    .bookTypeStyle {
        margin-left: 10px;
        padding-left: 1px !important;
        width: 160px;
    }

    /* 模糊过滤 */
    .filterStringTypeStyle {
        margin-left: 10px;
        width: 180px;
    }

    /* 搜索的样式 */
    .searchBookTypeStyle {
        margin-left: 10px;
        width: 70px;
    }

    /* 重置的样式 */
    .resetBookTypeStyle {
        margin-left: 10px;
        width: 70px;
    }



    /*  使用deep样式穿透 面包屑导航里面字体样式 */
    .bookTypeClassNavi /deep/ .el-breadcrumb {
        font-size: 20px !important;
    }
</style>