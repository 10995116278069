<template>
    <div class='firstpage-header'>
        <!-- 标题 -->
        <div class="article_type">
            <ul>
                <li :class="li_style" @click="tabClick('按日期排序')">按日期排序</li>
                <li :class="li_style2" @click="tabClick('按热度排序')">按热度排序</li>
            </ul>
        </div>

        <!--  <el-submenu :index="item.id"  v-for="item in menuList" :key="item.id"> -->
        <div class="article">
            <el-row>
                <el-col :span="8" v-for="item in article_content_list" :key="item.contentId" class="article_body">
                    <el-card>
                        <!-- 列表内容信息  onerror代表加载图片失败的时候加载本地图片 -->
                        <div class="article_content">
                            <!-- 图片 -->
                            <p class="article_content_left_logo"><img :src="item.titleIcon" :onerror="headError"></p>

                            <!-- 标题 使用动态绑定{{}}-->
                            <div class="article_title">
                                <el-button type="text" class="article_title_button" @click='seeArticleDetail(item)'>
                                    {{item.articleTitle}}</el-button>

                                <!-- 文章分类 -->
                                <el-button type="primary" plain="" class="article_title_button2"
                                    @click='seeArticleType(item)'>
                                    {{item.articleType}}</el-button>
                            </div>

                            <!-- 右侧---中间部分信息 -->
                            <p class="article_middle_content">
                                <span>{{item.articleIntroduction}}</span>
                            </p>

                            <!-- 右侧---底部信息 -->
                            <div class="article_content_other">
                                <span>时间: {{handleCreatDate(item.createdTime)}}</span>
                                <span>阅读量: {{item.articleViews}}</span>
                                <el-button type="primary" plain="" class="article_content_button"
                                    @click='seeArticleDetail(item)'>阅读更多</el-button>
                            </div>
                        </div>

                    </el-card>
                </el-col>
            </el-row>
        </div>

        <!-- 页码 -->
        <div class="articlePageClass">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="articlePage" :page-size="articlePageSize" :hide-on-single-page="paginationHide"
                layout="total, prev, pager, next, jumper" :total="articleTotal" class="articlePage-list">
            </el-pagination>
        </div>
    </div>
</template>



<!-- 引入外部js文件 -->
<script>
    export default {
        // SEO化，动态设置, 
        metaInfo() {
            return {
                title: this.headTitle,
                meta: [{
                    charset: "utf-8"
                },
                {
                    name: 'keyWords',
                    content: this.headKeyWord,
                },
                {
                    name: 'description',
                    content: this.headDescription,
                }]
            }
        },

        data() {
            return {
                // li_style和li_style2为动态样式
                li_style: 'li_select',
                li_style2: 'li_normarl',
                paginationHide: true,//底部页码是否隐藏，默认隐藏true

                // 文章内容列表
                article_content_list: [],
                articlePage: 1,  //文章页码数，默认每页5条数据
                articleState: 0,  //状态码，0代表按照日期排序，1代表
                articleTypeId: 0,  //文章类型，0代表全部
                articleTotal: 0,  //文章总数
                articlePageSize: 5, //默认每页5条数据

                headError: 'this.src="' + require('../../assets/images/weixin-gongzhonghao.png') + '"',  //加载本地图片

                navigateItem: [],//导航栏----标题数据

                // SEO化
                headTitle:'飞哥个人博客',
                headKeyWord:'个人博客, 个人网站, python, Qt, iOS, 前端',
                headDescription:'飞哥, 个人博客, 个人网站, python, Qt',
            }
        },

        //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
        created() {
            console.log('---articleState----', this.articleState);
            console.log('---articlePage----', this.articlePage);
            let articlePageNew = window.sessionStorage.getItem('articlePage')
            if (Number(articlePageNew) > 0) {
                this.articlePage = Number(articlePageNew);
            }
            let articleStateNew = window.sessionStorage.getItem('articleState')
            if (Number(articleStateNew) > 0) {
                this.articleState = Number(articleStateNew);
            }

            let li_styleNew = window.sessionStorage.getItem('li_style')
            if (li_styleNew) {
                this.li_style = li_styleNew;
                this.li_style2 = window.sessionStorage.getItem('li_style2');
            }
            this.articleTypeId = 0;
            this.getArticleTypeNaviBar();
            this.getProjectList();
        },

        methods: {
            //点击选中，进行动态样式切换
            tabClick(tabTitle) {
                console.log('2222222');
                console.log(tabTitle);
                if (tabTitle == '按日期排序') {
                    this.li_style = 'li_select';
                    this.li_style2 = 'li_normarl'
                    this.articleState = 0;
                } else {
                    this.li_style = 'li_normarl';
                    this.li_style2 = 'li_select';
                    this.articleState = 1;
                }
                window.sessionStorage.setItem('li_style', this.li_style);
                window.sessionStorage.setItem('li_style2', this.li_style2);
                window.sessionStorage.setItem('articleState', this.articleState);
                this.articlePage = 1;
                window.sessionStorage.setItem('articlePage', this.articlePage);
                this.getProjectList();
            },

            // 监听pageSize改变事件
            handleSizeChange(newSize) {
                console.log('handleSizeChange');
                this.articlePage = newSize;
                window.sessionStorage.setItem('articlePage', this.articlePage);
                this.getProjectList();
            },

            // 监听当前页码改变
            handleCurrentChange(newPage) {
                console.log('handleCurrentChange');
                this.articlePage = newPage;
                window.sessionStorage.setItem('articlePage', this.articlePage);
                this.getProjectList();
            },

            // 获取文章分类列表, async代表异步操作
            async getArticleTypeList() {
                const { data: res } = await this.$http.get('/article/type/list', {
                    params: {
                        page: 1, //文章分类列表页码数，默认每页5条数据
                    }
                });
                if (res.status !== 200) {
                    return this.$message.error('获取平台列表失败');
                }
                this.articleTypeParentList = res.typeParentList;
            },

            // 获取项目列表, async代表异步操作
            async getProjectList() {
                let token = window.sessionStorage.getItem('token');
                const { data: res } = await this.$http.get('/article/list', {
                    params: {
                        page: this.articlePage,
                        state: this.articleState,
                        articleTypeId: this.articleTypeId,
                        token:token,
                    },
                });
                console.log(res);
                if (res.status !== 200) {
                    return;
                }
                console.log('数据来了');
                console.log(res.totalPage);
                this.article_content_list = res.articleList;
                this.articleTotal = res.totalNumber;
                if (res.totalNumber > 0) {
                    this.paginationHide = false;
                }
            },

            // 获取菜单栏NaviBar, async代表异步操作
            async getArticleTypeNaviBar() {
                const { data: res } = await this.$http.get('/article/type/naviBar', {
                    params: {
                        username: window.sessionStorage.getItem('userEmail'),     //用户邮箱
                    }
                });
                this.navigateItem = res.typeList;
                window.sessionStorage.setItem('navigateItem', JSON.stringify(this.navigateItem));
            },


            // 查看对应分类页面
            seeArticleType(item) {
                console.log("查看对应分类页面");
                console.log(item.articleTypeId);
                console.log(item.articleType);
                console.log(item.articleTypeDetail);
                var activePathStr = '/technology-1';//设置默认切换文章分类页面
                this.navigateItem.forEach((naviItem, index) => {
                    naviItem.items.forEach((naviItem2) => {
                        if (naviItem2.key == item.articleTypeId) {
                            activePathStr = '/' + naviItem2.path;
                        }
                    });
                });
                window.sessionStorage.setItem('activePath', activePathStr);
                window.sessionStorage.setItem('articleTypeId', item.articleTypeId);
                window.sessionStorage.setItem('typeTitle', item.articleTypeDetail);
                window.sessionStorage.setItem('typeContent', item.articleTypeContent);
                window.sessionStorage.setItem('li_style', 'li_select');
                window.sessionStorage.setItem('li_style2', 'li_normarl');
                window.sessionStorage.setItem('articleState', 0);
                this.articlePage = 1;
                window.sessionStorage.setItem('articlePage', this.articlePage);
                this.$router.push(activePathStr);
            },

            // 查看详情页面
            seeArticleDetail(item) {
                console.log("查看详情页面------1111111");
                console.log("articleLogin------",item.articleLogin);
                let token = window.sessionStorage.getItem('token');
                if (token==null) {
                    token = "空的";
                }
                if (item.articleLogin && token.length < 10) {
                    this.$router.push('/login');
                    return this.$message.error('本文章详情需要登录才能访问');
                }

                window.sessionStorage.setItem('activePath', '/technology-1');
                this.navigateItem.forEach((naviItem) => {
                    naviItem.items.forEach((naviItem2) => {
                        if (naviItem2.key == item.articleTypeId) {
                            window.sessionStorage.setItem('activePath', '/' + naviItem2.path);
                        }
                    });
                });
                window.sessionStorage.setItem('articleId', item.articleId);
                window.sessionStorage.setItem('articleTypeId', item.articleTypeId);
                window.sessionStorage.setItem('typeTitle', item.articleTypeDetail);
                window.sessionStorage.setItem('typeContent', item.articleTypeContent);
                this.$router.push({ path: 'articleDetail', query: { articleId: item.articleId } });
            },


            // 处理content数据
            handleContent: function (contentStr) {
                let contentArr = JSON.parse(contentStr);
                if (contentArr.length > 0) {
                    let childrenArr = contentArr[0].children
                    if (childrenArr.length > 0) {
                        return childrenArr[0].text;
                    }
                }
                return "";
            },

            // 处理文章日期进行处理
            handleCreatDate: function (dateStr) {
                let contentArr = dateStr;
                // console.log(dateStr);
                if (dateStr.length > 10) {
                    contentArr = dateStr.slice(0, 10);
                }
                return contentArr;
            },

        }
    };
</script>



<style lang="less" scoped>
    body {
        margin: 0px;
        padding: 0px;
    }

    ul li {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    /* 头部 */
    .firstpage-header {
        /*  布局自适应 */
        margin: auto;
        padding: 0px;
        background-color: rgb(228, 229, 233);
        /* background-color: white; */
        display: block;
        /* 文本对齐方式 */
        /* justify-content: center; */
        /* 字体颜色 */
        color: black;
        width: 70%;
    }

    /* 文章分类 */
    .article_type {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        background-color: white;
    }

    /* 按日期和热度排序的 普通状态 */
    .li_normarl {
        /* 水平布局 */
        float: left;
        display: block;
        width: 120px;
        height: 30px;
        justify-content: center;
        text-align: center;
        padding: 10px;
        font-size: 20px !important;
    }

    .li_normarl:hover {
        border-bottom: #00c1de 2px solid;
    }

    /* 按日期和热度排序的 选中状态 */
    .li_select {
        float: left;
        display: block;
        width: 120px;
        height: 30px;
        justify-content: center;
        text-align: center;
        padding: 10px;
        color: #00c1de;
        border-bottom: #00c1de 2px solid;
        font-size: 20px !important;
    }


    /* 单个列表为一个整体 */
    .article_body {
        width: 100%;
        padding-top: 10px;
    }

    .article_title {
        margin: 0px;
        padding: 0px;
    }

    /* 右侧栏---标题 */
    .article_title_button {
        /* 左侧距离 */
        margin-left: 160px;
        margin-top: 10px;
        font-size: 18px !important;
        height: 30px !important;
        padding: 5px 0px !important;
        color: #000;
    }

    /* 右侧栏---标题 悬停状态*/
    .article_title_button :hover {
        color: #00c1de !important;
    }

    /* 文章分类样式 */
    .article_title_button2 {
        float: right;
        background: #ecf5ff !important;
        color: #00c1de !important;
        border-color: #00c1de !important;
        width: 80px;
        height: 30px;
        font-size: 14px;
        /* 文字在按钮里面距离top、right、bottom、left的距离 */
        padding: 5px 5px !important;
        margin-top: 8px !important;
    }

    /* 文章分类样式 悬停*/
    .article_title_button2:hover {
        color: white !important;
        background: #00c1de !important;
    }


    div {
        margin: 0px;
        padding: 0px;
    }

    /* 中间内容 */
    .article_content {
        margin-top: 0px;
        display: block;
        height: 150px;
    }

    /* 左侧图片 */
    .article_content_left_logo {
        width: 150px;
        height: 150px;
        /* 父元素开启绝对定位 */
        position: absolute;
        overflow: hidden;
    }

    /* 图片样式  图片开启相对定位*/
    .article_content_left_logo img {
        width: 100%;
        /* 子元素开启相对定位  此处子元素是图片img*/
        position: relative;

        /* top此时表示偏移量，距离顶部的偏移量 */
        top: 50%;
        transform: translateY(-50%);

        /* 
        -moz代表firefox浏览器私有属性
        -ms代表ie浏览器私有属性（360浏览器是ie内核）
        -webkit代表safari、chrome私有属性
        -o代表opera私有属性
        */
        -ms-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }

    /* 右侧---中间内容 */
    .article_middle_content {
        /* 外框距离左边，最好大于图片的宽度 */
        margin-left: 160px;
        /*外框距离上部距离  */
        margin-top: 5px;
        height: 50px !important;
        overflow: hidden;
    }

    .article_middle_content span {
        font-size: 16px;
        color: #000;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        padding-top: 1px;
    }

    /* 底部---文章分类、时间 */
    .article_content_other {
        margin-left: 160px;
        font-size: 14px;
        padding-top: 24px;
        height: 30px;
    }

    .article_content_other span {
        margin-right: 20px;
        margin-top: 10px;
        padding-top: 10px;
        color: #999;
    }

    /* 底部---阅读更多样式 */
    .article_content_button {
        float: right;
        background: #ecf5ff !important;
        color: #00c1de !important;
        border-color: #00c1de !important;
        width: 80px;
        height: 30px;
        font-size: 14px;
        /* 文字在按钮里面距离top、right、bottom、left的距离 */
        padding: 5px 5px !important;
    }

    /* 文章分类样式 悬停*/
    .article_content_button:hover {
        color: white !important;
        background: #00c1de !important;
    }







    /* 列表总数及分页 */
    .articlePageClass {
        background-color: white;
        margin-top: 10px !important;
        padding: 10px !important;
    }

    /*  使用deep样式穿透 共几条 */
    .articlePageClass /deep/ .el-pagination__total {
        font-size: 16px;
    }

    /*  使用deep样式穿透 页码 */
    .el-pagination /deep/ .el-pager li {
        font-size: 16px;
    }

    /*  使用deep样式穿透 前往 */
    .articlePageClass /deep/ .el-pagination__jump {
        font-size: 16px;
    }

    /*  使用deep样式穿透 当前页码 */
    .articlePageClass /deep/ .el-pagination__editor {
        font-size: 16px;
    }

    .articlePage-list {
        color: black;
    }

    /*  使用deep样式穿透 在页码悬停状态 */
    .articlePage-list /deep/ .el-pager li:hover {
        color: #00c1de;
    }

    /*  使用deep样式穿透 在页码选中状态 */
    .articlePage-list /deep/ .el-pager li.active {
        color: #00c1de !important;
    }
</style>