 /* jshint esversion: 6 */
import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/Home.vue';
import Login from '../components/login.vue';
import Register from '../components/register.vue';
import PasswordReset from '../components/passwordReset.vue';
import Firstpage from '../components/firstPage/firstpage.vue';
import TechnologyPage from '../components/technology/technologyPage.vue';
import AboutMe from '../components/aboutMe.vue';

import AdminManage from '../components/adminManage/adminManage.vue';
import ArticleList from '../components/adminManage/articleList.vue';
import ArticleType from '../components/adminManage/articleType.vue';
import ArticleTag from '../components/adminManage/articleTag.vue';
import ArticleAdd from '../components/adminManage/articleAdd.vue';
import ArticleEdit from '../components/adminManage/articleEdit.vue';
import UserList from '../components/adminManage/userList.vue';
import Welcome from '../components/adminManage/welcome.vue';
import ArticleDetail from '../components/firstPage/articleDetail.vue';

import BookList from '../components/accountBook/bookList.vue';
import BookType from '../components/accountBook/bookTypeList.vue';



// 使用VueRouter
Vue.use(VueRouter);

// 全局样式表引入
const routes = [
  {
    path:'/',
    //redirect重定向为渲染的路径,当访问根路径/的时候直接重定向到/home
    redirect:'/home'
  },
  {
    // 登录页面
    path:'/login',
    component:Login,
  },
  {
    // 注册页面
    path:'/register',
    component:Register,
  },
  {
    // 忘记密码页面
    path:'/passwordReset',
    component:PasswordReset,
  },
  

  {
    //后台管理
    path:'/adminManage',
    component:AdminManage,
    redirect:'/welcome',
    children:[
      {
        path:'/welcome',
        component:Welcome,
      },
      {
        //文章列表
        path:'/articleList',
        component:ArticleList,
      },
      {
        //文章分类
        path:'/articleType',
        component:ArticleType,
      },
      {
        //文章标签
        path:'/articleTag',
        component:ArticleTag,
      },
      {
        //用户列表
        path:'/userList',
        component:UserList,
      },
      {
        //新建文章
        path:'/articleAdd',
        component:ArticleAdd,
      },
      {
        //文章编辑
        path:'/articleEdit',
        component:ArticleEdit,
      },


      // BookList
      {
        //消费记录
        path:'/bookList',
        component:BookList,
      },

      {
        //消费分类
        path:'/bookType',
        component:BookType,
      },
    ]
  },

  {
    //前端页面----人人可以访问的页面
    path: '/home',
    component: Home,
    redirect:'/firstPage',
    //子路由
    children:
    [{
      path:'/firstPage',
      component:Firstpage,
    },
    {
      path:'/technology-1',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-2',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-3',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-4',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-5',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-6',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-7',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-8',
      component:TechnologyPage,
    }, 
    {
      path:'/technology-9',
      component:TechnologyPage,
    }, 
    {
      path:'/life-1',
      component:TechnologyPage,
    }, 
    {
      path:'/life-2',
      component:TechnologyPage,
    },     
    {
      path:'/life-3',
      component:TechnologyPage,
    }, 
    {
      path:'/life-4',
      component:TechnologyPage,
    }, 
    {
      path:'/life-5',
      component:TechnologyPage,
    }, 
    {
      path:'/life-6',
      component:TechnologyPage,
    }, 
    {
      path:'/life-7',
      component:TechnologyPage,
    }, 
    {
      path:'/life-8',
      component:TechnologyPage,
    }, 
    {
      path:'/life-9',
      component:TechnologyPage,
    }, 
    {
      path:'/more-1',
      component:AboutMe,
    }, 
    {
      path:'/more-2',
      component:TechnologyPage,
    }, 
    {
      path:'/more-3',
      component:TechnologyPage,
    }, 
    {
      path:'/more-4',
      component:TechnologyPage,
    }, 
    {
      path:'/more-5',
      component:TechnologyPage,
    }, 
    {
      path:'/more-6',
      component:TechnologyPage,
    }, 
    {
      path:'/more-7',
      component:TechnologyPage,
    }, 
    {
      path:'/more-8',
      component:TechnologyPage,
    }, 
    {
      path:'/more-9',
      component:TechnologyPage,
    }, 
    {
      path:'/articleDetail',
      name:'articleDetail',
      component:ArticleDetail,
    },
    ]
  }
];

// 创建VueRouter 实例
const router = new VueRouter({
  mode:'hash',
  base: process.env.BASE_URL,
  routes
});


// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  //  next()  放行    next('/login')  强制跳转
  console.log(to.path);

  let pathNew = to.path;
  if (pathNew === '/login' || pathNew === '/firstPage' || pathNew === '/articleDetail' || 
  pathNew === '/passwordReset' || pathNew === '/register' || 
  pathNew.startsWith('/life-') || pathNew.startsWith('/technology-') || pathNew.startsWith('/more-')) {
    return next();
  }
  
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token');
  if (!tokenStr) {
    return next('/login');
  }
  next();
});

export default router;
