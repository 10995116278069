<!-- UI结构 -->
<template>
    <div class="login-container">
        <div class="login-box">
            <!-- 登录表单 -->
            <!-- 通过ref来绑定实例对象loginFromRef -->
            <!-- :model进行数据绑定 loginFrom此时是一个对象-->
            <!-- :rules表单的验证规则 loginFromRules此时一个验证规则对象   label-width="0px"-->
            <el-form ref='loginFromRef' :model="loginFrom" :rules="loginFromRules" class="login_from">
                <!-- 用户名称即是用户邮箱 -->
                <!-- 通过prop来将userName绑定到loginFromRules验证对象 -->
                <el-form-item prop="userName" class="login-email">
                    <!-- v-model进行双向绑定到对象loginFrom -->
                    <el-input v-model="loginFrom.userName" placeholder="请输入用户qq邮箱"
                        prefix-icon="iconfont el-icon-third-user_name"></el-input>
                </el-form-item>

                <!-- 密码 -->
                <el-form-item prop="userPassword">
                    <el-input v-model="loginFrom.userPassword" type="password" placeholder="请输入用户密码"
                        prefix-icon="iconfont el-icon-third-user_password"></el-input>
                </el-form-item>

                <!-- 按钮区域 -->
                <el-from-item class="log-btn">
                    <el-button type="primary" @click="loginBtn" style="width: 400px;">登录</el-button>
                </el-from-item>

                <el-form-item class="login-other-btn">
                    <el-button type="text" @click="forgetPasswordBtn">忘记密码</el-button>
                    <el-button type="text" class="login-register" style="margin-left: 200px;" @click="registerBtn">注册
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                // 登录表单的数据对象绑定
                loginFrom: {
                    userName: '',  //1806339272@qq.com
                    userPassword: '', 
                },

                //表单验证规则对象
                loginFromRules: {
                    //验证用户邮箱是否合法
                    userName: [
                        { required: true, message: '请输入用户qq邮箱', trigger: 'blur' },
                        { min: 6, max: 25, message: '长度在6到25个字符', trigger: 'blur' },
                    ],

                    // 验证密码是否合法
                    userPassword: [
                        { required: true, message: '请输入用户密码', trigger: 'blur' },
                        { min: 6, max: 25, message: '长度在6到25个字符', trigger: 'blur' }
                    ],
                }
            };
        },

        //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
        created() {
            console.log('created------login--');
            this.title = '登录';
            document.title = '登录';
            this.loginFrom.userName = window.localStorage.getItem('feiGeUserEmail');
        },

        methods: {

            //登录操作事件
            loginBtn() {
                if (!this.loginFrom.userName) {
                    this.$message.error('请输入用户qq邮箱');
                    return;   
                }
                const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
                if (regEmail.test(this.loginFrom.userName) == false) {
                    this.$message.error('请输入合法的qq邮箱');
                    return;
                }
                if (!this.loginFrom.userPassword) {
                    this.$message.error('请输入用户登录密码');
                    return;   
                }

                // async表示异步请求
                this.$refs.loginFromRef.validate(async valid => {
                    if (!valid) {//自动检验不合格
                        this.$message.error('请输入正确格式的qq邮箱和密码');
                        return;
                    }
                    console.log('登录请求开始。。。');
                    console.log(this.$md5(this.loginFrom.userPassword));


                    // "password": this.loginFrom.userPassword
                    const { data: res } = await this.$http.post("/user/login", {
                        "userName": this.loginFrom.userName,
                        "password": this.$md5(this.loginFrom.userPassword)
                    });
                    
                    console.log(res);
                    if (res.status !== 200) {//请求失败
                        this.$message.error('输入的qq邮箱或者用户密码有误');
                        return;
                    }
                    this.$message.success('登录成功');

                    // 1. 将登录成功之后的 token，保存到客户端的 sessionStorage 中
                    //   1.1 项目中出了登录之外的其他API接口，必须在登录之后才能访问
                    //   1.2 token 只应在当前网站打开期间生效，所以将 token 保存在 sessionStorage 中

                    /*
                     *sessionStorage为会话存储，生命周期是在仅在当前会话下有效，
                     *但是sessionStorage在关闭了浏览器窗口后就会被销毁。同时独立的打开同一个窗口同一个页面，sessionStorage也是不一样的。
                     */
                    window.sessionStorage.setItem('userName', res.data.userName);
                    window.sessionStorage.setItem('token',res.token);
                    window.sessionStorage.setItem('userEmail',res.data.email);
              
                    /*
                     *localStorage为本地存储，生命周期是永久的，关闭页面或浏览器之后localStorage中的数据也不会消失。
                     *localStorage除非主动删除数据，否则数据永远不会消失。
                     */
                    window.localStorage.setItem('feiGeUserEmail',res.data.email);
                    // this.Common.userName = res.data.userName;
                    // this.Common.isSuperuser = res.data.isSuperuser;
                    // this.Common.userId = res.data.userId;

                    // 2. 通过编程式导航跳转到前端主页，路由地址是 /home
                    this.$router.push('/home');

                })
            },

            //进入忘记密码界面
            forgetPasswordBtn() {
                console.log('forgetPasswordBtn');
                this.$router.push('/passwordReset');
            },

            //进入注册界面
            registerBtn() {
                console.log('registerBtn');
                this.$router.push('/register');
            },
        },
    }

</script>






<style lang="less" scoped>
    .login-container {
        width: 100%;
        height: 100%;
        background-color: rgb(242, 242, 242);
    }

    /* 登录界面模块 */
    .login-box {
        width: 450px;
        height: 350px;
        background-color: #ffffff;
        /* 设置圆角 */
        border-radius: 3px;
        /* 开启绝对定位 */
        position: absolute;
        left: 50%;
        top: 50%;
        /* 位移操作，参考自己的位置来平移，向X轴平移，填正数往右平移，填负数，往左平移，向Y轴平移，填正数往下平移，填负数，往上平移*/
        transform: translate(-50%, -50%);
    }

    .login_from {
        /* 开启绝对定位 */
        position: absolute;
        top: 40px;
        width: 100%;
        /* 设置左右间距 */
        padding: 0 20px;
        /* 设置表单样式 */
        box-sizing: border-box;
    }

    .login_from /deep/ .el-input__inner {
        font-size: 16px !important;
        margin-top: 10px;
    }

    .log-btn {
        /* 设置为弹性框模型 */
        display: flex;
    }

    .log-btn /deep/ .el-button {
        justify-content: center;
        margin-top: 20px;
        margin-left: 5px;
    }

    .login-other-btn {
        margin-top: 20px;
    }

    .login-other-btn /deep/ .el-button {
        justify-content: center !important;
        margin-left: 5px;
        width: 100px !important;
    }
</style>