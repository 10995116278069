/* jshint esversion: 6 */
import Vue from 'vue';
import Common from '../components/common.vue';

//导入组件,Message为弹窗提醒  FormItem 
import { Button, Form, FormItem, Input, Message, 
    Container, Header, Aside, Main, Footer,
    Menu, Submenu, MenuItem, 
    Breadcrumb, BreadcrumbItem, Card,
    Row, Col, 
    Table, TableColumn, Tooltip, Switch,
    Pagination, Dialog, 
    Select, Option, MessageBox, DatePicker,
    Tabs, TabPane, Link, RadioGroup,RadioButton,
} from 'element-ui';


import 'element-ui/lib/theme-chalk/index.css';
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo);

//进行全局注册
Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);

Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);

Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);

Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Card);

Vue.use(Row);
Vue.use(Col);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Tooltip);
Vue.use(Switch);

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Select);
Vue.use(Option);
Vue.use(DatePicker);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Link);

Vue.use(RadioGroup);
Vue.use(RadioButton);


//将Message挂载在Vue上面
Vue.prototype.$message = Message;

//将MessageBox 弹框挂载在Vue上面
Vue.prototype.$confirm = MessageBox.confirm;

//将common.vue 全局变量挂载在Vue上面
Vue.prototype.Common = Common;


// Vue.prototype.editor = Editor;
// Vue.prototype.toolbar = Toolbar;


//将时间戳转化日期格式 ,全局过滤器，dateFormat为过滤器的名称，function(originVal)为过滤函数，originVal为形参
Vue.filter('dateFormat',function(originVal) {
    const tempDate = new Date(originVal);
    let y = tempDate.getFullYear();
    //getMonth是从零开始的，所以需要加1；  padStart:将字符串按照2位数显示，不足前位自动补0
    const m = (tempDate.getMonth() + 1 + '').padStart(2,'0');
    const d = (tempDate.getDay() + '').padStart(2,'0');

    const hh = (tempDate.getHours() + '').padStart(2,'0');
    const mm = (tempDate.getMinutes() + '').padStart(2,'0');
    const ss = (tempDate.getSeconds() + '').padStart(2,'0');
    return '${y}-${m}-${d} ${hh}:${mm}:${ss}';
});






