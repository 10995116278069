<template>
    <div class="about-body">
        <div class="aboutMainView">
            <!-- 标题 -->
            <div class="about_type_content">
                <h1>关于作者</h1>
                <p>飞哥，是一名90后全栈开发者,</p>
                <p>学习的编程语言比较多，</p>
                <p>学过iOS、swift、Unity、H5、python、C/C++等编程技术。</p>
                <p>坐标湖北武汉。</p>
            </div>

            <!-- 标题2 -->
            <div class="about_type_website">
                <h1>关于本站</h1>
                <p>本站始建于2022年10月5日，历尽千辛万苦，利用国庆长假，终于成功上线个人博客网站。</p>
                <p>本人是自学前端H5和后端python技术，一名是喜欢自学技术的程序员。</p>
                <p>前端是利用vue+H5。</p>
                <p>后端是利用python+django框架。</p>
                <p>数据库是使用Mysql。</p>
                <p>利用uwsgi+nginx进行前后端部署。</p>
                <p> 富文本采用<a href="https://www.wangeditor.com/" target="_blank">wangEditor。</a>
                <p>尝试搭建的个人网站, 意在技术实践，志于技术提高。</p>
                <p>联系邮箱： hbwhfg@qq.com。</p>
            </div>
        </div>
    </div>

</template>


<script>
    export default {
        data() {
            return {
                typeTitle: "",      //分类名称
                typeContent: "",    //分类说明
            }
        },

        //created:在模板渲染成html前调用，即通常初始化某些属性值，然后再渲染成视图。
        created() {
            this.typeContent = window.sessionStorage.getItem('typeContent');
            this.typeTitle = window.sessionStorage.getItem('typeTitle');
            this.articleTypeId = window.sessionStorage.getItem('articleTypeId');
            console.log(this.typeContent);
        },

        // 监听操作
        watch: {
            '$route'(to, from) {
                console.log("监听操作---------");
                console.log(to);
                console.log(from);
                this.typeContent = window.sessionStorage.getItem('typeContent');
                this.typeTitle = window.sessionStorage.getItem('typeTitle');
                this.articleTypeId = window.sessionStorage.getItem('articleTypeId');
            }
        },

    }
</script>


<style lang="less" scoped>
    body {
        margin: 0px;
        padding: 0px;
    }

    ul li {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    .about-body {
        /*  背景颜色 */
        margin: 0px;
        padding: 0px;
        background-color: rgb(228, 229, 233);
    }

    /* 头部 */
    .aboutMainView {
        background-color: white;
        display: block;
        /* 文本对齐方式 */
        /* justify-content: center; */
        /* 字体颜色 */
        color: black;
        width: 100%;
    }

    /* 分类标题及说明 */
    .about_type_content {
        margin-left: 20px;
        margin-top: 10px;
    }

    .about_type_content h1 {
        height: 20px;
        font-size: 24px;
    }

    .about_type_content span {
        display: block;
        font-size: 16px;
        padding-top: 10px;
    }

    .about_type_website {
        margin-left: 20px;
        margin-top: 5px;
        padding-bottom: 20px;
    }
</style>