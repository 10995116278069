<template>
    <!-- 文章分类 -->
    <div>
        <div class="articleClassNavi">
            <!-- 面包屑导航---- -->
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/welcome' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>文章管理</el-breadcrumb-item>
                <el-breadcrumb-item>分类</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 卡片视图区域  -->
        <el-card class="box-card">
            <!-- gutter设置el-col之间的间距 -->
            <el-row :gutter="20">
                <!-- 添加按钮 -->
                <el-col :span="4">
                    <el-button type="primary" @click='addArticleTypeDialogVisible = true'>
                        新建分类
                    </el-button>
                </el-col>
            </el-row>

            <!-- 平台列表  ：data绑定数据源，prop指定参数对应的值， border边框，stripe隔行变色-->
            <el-table :data="articleTypeList" border stripe style="width: 100%"
                :header-cell-style="{'text-align':'center'}">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="typeId" label="分类ID" width="70" align="center">
                </el-table-column>
                <el-table-column prop="grate" label="分类等级" width="80" align="center">
                </el-table-column>

                <el-table-column prop="parentName" label="父类名称" width="100" align="center">
                </el-table-column>
                <el-table-column prop="subName" label="子类名称" width="100" align="center">
                </el-table-column>
                <el-table-column prop="articleNum" label="文章数量" width="80" align="center">
                </el-table-column>
                <el-table-column prop="content" label="说明" width="200" align="center">
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" width="150" align="center">
                </el-table-column>
                <el-table-column label="操作">
                    <!-- 作用域插槽的使用 -->
                    <template slot-scope="scope">
                        <!-- enterablez 鼠标是否可进入到 tooltip 中 自动隐藏-->
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <!-- 编辑按钮 -->
                            <el-button type="primary" icon="el-icon-edit" @click="showArticleTypeEditDialog(scope.row)">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <!-- 删除按钮 -->
                            <el-button type="danger" icon="el-icon-delete"
                                @click="removeArticleTypeInfor(scope.row.typeId)">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 页码 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="articleTypeInfor.page" :page-size="articlePageSize"
                layout="total, prev, pager, next, jumper" :total="articleTotal" class="articlePage-list">
            </el-pagination>

            <!-- 添加文章分类的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="添加分类" :visible.sync="addArticleTypeDialogVisible" width="50%"
                @close="addArticleTypeDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
                    <!-- prop验证规则属性 v-model双向绑定数据-->
                    <el-form-item label="父级" prop="parentName">
                        <el-select v-model="addForm.parentName" placeholder="请选择父级">
                            <el-option label="顶级" value="顶级"></el-option>
                            <!-- 外层for循环，里面el-option采用v-if条件判断 -->
                            <template>
                                <div v-for="(item, index) in articleTypeParentList" :key="index">
                                    <!-- 判断是否父级类 -->
                                    <el-option v-if="item.grate == 1 " :label="item.parentName"
                                        :value="item.parentName"></el-option>
                                </div>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="分类名称" prop="subName">
                        <el-input v-model="addForm.subName"></el-input>
                    </el-form-item>

                    <el-form-item label="分类介绍" prop="content">
                        <el-input v-model="addForm.content"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer">
                    <el-button @click="addArticleTypeDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addArticleType">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 修改文章分类的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="修改分类信息" :visible.sync="editArticleTypeDialogVisible" width="50%"
                @close="editUserDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="父级" prop="parentName">
                        <el-input v-model="editForm.parentName" :disabled="editParentVisible"></el-input>
                    </el-form-item>
                    <el-form-item label="分类名称" prop="subName">
                        <el-input v-model="editForm.subName"></el-input>
                    </el-form-item>
                    <el-form-item label="分类介绍" prop="content">
                        <el-input v-model="editForm.content"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer">
                    <el-button @click="editArticleTypeDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editArticleTypeInfor">确 定</el-button>
                </div>
            </el-dialog>
        </el-card>

    </div>
</template>



<!-- 行为 -->
<script>
    export default {
        // 数据处理
        data() {
            return {
                //获取文章分类列表的所需要设置的参数
                articleTypeInfor: {
                    page: 1, //文章分类列表页码数，默认每页5条数据
                },
                articleTotal: 0,  //文章分类总数
                articlePageSize: 5, //默认每页5条数据

                //文章分类列表---每页最多5条数据
                articleTypeList: [],

                //文章一级分类列表，所有的一级分类
                articleTypeParentList: [],

                // 控制添加文章分类对话框的显示与隐藏
                addArticleTypeDialogVisible: false,

                //添加文章分类的表单数据对象
                addForm: {
                    parentName: '', //父类
                    subName: '',    //分类名称---有可能是父类名称，也有可能是子类名称
                    content: '',    //说明，介绍
                },

                // 添加文章分类表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addFormRules: {
                    parentName: [
                        { required: true, message: '请选择父级', trigger: 'blur' }
                    ],
                    subName: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '分类名称长度在2到15个字符', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请输入分类介绍', trigger: 'blur' },
                    ],
                },

                // 控制修改文章分类对话框的显示与隐藏
                editArticleTypeDialogVisible: false,

                //父类（一级）是否顶级，如果是true, 否则是false,
                editParentVisible: false,

                //获取到修改平台的数据对象
                editForm: {},

                // 修改平台表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                editFormRules: {
                    parentName: [
                        { required: true, message: '请输入父级', trigger: 'blur' },
                        { min: 2, max: 15, message: '父级长度在2到15个字符', trigger: 'blur' }
                    ],
                    subName: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '分类名称长度在2到15个字符', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请输入分类介绍', trigger: 'blur' },
                    ],
                }
            }
        },

        //创建
        created() {
            this.getArticleTypeList();
        },

        // 方法
        methods: {
            // 获取文章分类列表, async代表异步操作
            async getArticleTypeList() {
                const { data: res } = await this.$http.get('/article/type/list', {
                    params: this.articleTypeInfor,
                });
                if (res.status !== 200) {
                    return this.$message.error('获取文章分类列表失败');
                }
                this.articleTypeList = res.typeList;
                this.articleTypeParentList = res.typeParentList;
                this.articleTotal = res.totalNumber;
            },

            // 监听pageSize改变事件
            handleSizeChange(newPage) {
                console.log('handleSizeChange');
                this.articleTypeInfor.page = newPage;
                this.getArticleTypeList();
            },

            // 监听当前页码改变
            handleCurrentChange(newPage) {
                console.log('handleCurrentChange');
                this.articleTypeInfor.page = newPage;
                this.getArticleTypeList();
            },

            // 监听添加文章分类对话关闭事件
            addArticleTypeDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addFormRef !== undefined) {
                    this.addFormRef.resetFields();
                }
            },

            // 点击新建分类按钮，添加文章分类信息
            addArticleType() {
                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                this.$refs.addFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/type/add', {
                        parentName: this.addForm.parentName,
                        subName: this.addForm.subName,
                        content: this.addForm.content,
                        token: window.sessionStorage.getItem('token'),
                    });
                    console.log(res.data);
                    if (res.status !== 200) {
                        return this.$message.error('新建文章分类失败');
                    }
                    this.$message.success('新建文章分类成功');

                    // 隐藏添加的对话
                    this.addArticleTypeDialogVisible = false;

                    //重新获取平台列表
                    this.getArticleTypeList();
                })
            },

            //点击编辑的修改文章分类弹窗
            showArticleTypeEditDialog(row) {
                console.log('编辑的修改平台弹窗');
                console.log(JSON.parse(JSON.stringify(row)));
                this.editArticleTypeDialogVisible = true;
                this.editForm = JSON.parse(JSON.stringify(row));
                console.log(this.editForm.grate);
                if (this.editForm.grate == 1) {
                    this.editParentVisible = true;
                } else {
                    this.editParentVisible = false;
                }
            },

            //修改平台弹窗关闭
            editUserDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.editFormRules !== undefined) {
                    this.editFormRules.resetFields();
                }
            },

            //修改分类信息，提交
            editArticleTypeInfor() {
                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                this.$refs.editFormRef.validate(async valid => {
                    console.log(valid);
                    if (!valid) {//预校验不通过
                        console.log('预校验不通过');
                        return;
                    }
                    console.log(this.editForm.id);

                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/type/edit', {
                        typeId: this.editForm.typeId,
                        parentName: this.editForm.parentName,
                        subName: this.editForm.subName,
                        content: this.editForm.content,
                        token: window.sessionStorage.getItem('token'),
                    });
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    if (res.status >= 400 && res.status <= 410) {//用户权限问题，需要登录
                        this.$router.push('/login');
                        return this.$message.error(res.msg);
                    } else {
                        if (res.status != 200) {
                            return this.$message.error(res.msg);
                        }
                    }
                    this.$message.success('修改分类成功');
                    // 隐藏修改平台的对话
                    this.editArticleTypeDialogVisible = false;

                    // 重新获取平台列表
                    this.getArticleTypeList();
                })
            },

            //删除文章分类信息
            async removeArticleTypeInfor(typeId) {
                // 询问是否真的删除当前
                const confirmResult = await this.$confirm('此操作将删除该文章分类, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => {
                    return err;
                });

                // 如果平台确认删除，则返回值为字符串 confirm 
                // 如果平台取消删除，则返回值为字符串 cancel 
                console.log(confirmResult);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除操作');
                }

                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                //预校验通过,
                const { data: res } = await this.$http.post('/article/type/delete', {
                    typeId: typeId,
                    token: window.sessionStorage.getItem('token'),
                });
                console.log(res.data);
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('删除文章分类成功');
                //重新获取平台列表
                this.getArticleTypeList();
            },

        },

    }
</script>


<!-- 样式 -->
<style lang="less" scoped>
    /*  使用deep样式穿透 面包屑导航里面字体样式 */
    .articleClassNavi /deep/ .el-breadcrumb {
        font-size: 20px !important;
    }
</style>