<!-- 用户列表页面 -->
<template>
    <div>
        <div class="userListNavi">
            <!-- 面包屑导航 -->
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{path: '/welcome' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>用户管理</el-breadcrumb-item>
                <el-breadcrumb-item>用户</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!-- 卡片视图区域  -->
        <el-card class="box-card">
            <!-- 标签列表  ：data绑定数据源，prop指定参数对应的值， border边框，stripe隔行变色-->
            <el-table :data="userList" border stripe style="width: 100%" :header-cell-style="{'text-align':'center'}">
                <el-table-column prop="userId" label="用户ID" width="70" align="center">
                </el-table-column>
                <el-table-column prop="userNick" label="用户昵称" width="80" align="center">
                </el-table-column>
                <el-table-column prop="userEmail" label="用户邮箱" width="160" align="center">
                </el-table-column>
                <el-table-column prop="userLevel" label="用户等级" width="80" align="center">
                </el-table-column>
                <el-table-column prop="'isSuperuser" label="管理员" :formatter="formatBooleanSuperuser" width="100"
                    align="center">
                </el-table-column>
                <el-table-column prop="isStaff" label="激活状态" :formatter="formatBooleanStaff" width="80" align="center">
                </el-table-column>
                <el-table-column prop="userAddress" label="用户地址" width="150" align="center">
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" width="150" align="center">
                </el-table-column>
                <el-table-column label="操作">
                    <!-- 作用域插槽的使用 -->
                    <template slot-scope="scope">
                        <!-- enterablez 鼠标是否可进入到 tooltip 中 自动隐藏-->
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <!-- 编辑按钮 -->
                            <el-button type="primary" icon="el-icon-edit" @click="showEditDialog(scope.row)">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <!-- 删除按钮 -->
                            <el-button type="danger" icon="el-icon-delete" @click="removeUserInfor(scope.row.userId)">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 页码 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="userInfor.page" :page-size="userPageSize" layout="total, prev, pager, next, jumper"
                :total="userTotal">
            </el-pagination>

            <!-- 修改文章标签的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
            <el-dialog title="修改用户信息" :visible.sync="editUserDialogVisible" width="50%" @close="editTagDialogClose">
                <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
                <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="100px">
                    <el-form-item label="用户昵称" prop="userNick">
                        <el-input v-model="editForm.userNick"></el-input>
                    </el-form-item>

                    <el-form-item label="用户等级" prop="userLevel">
                        <el-input v-model="editForm.userLevel"></el-input>
                    </el-form-item>

                    <el-form-item label="用户地址" prop="userAddress">
                        <el-input v-model="editForm.userAddress"></el-input>
                    </el-form-item>
                    <el-form-item label="用户介绍" prop="introduce">
                        <el-input v-model="editForm.introduce"></el-input>
                    </el-form-item>
                </el-form>

                <!-- 底部区域 -->
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editUserDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editTagInfor">确 定</el-button>
                </div>
            </el-dialog>

        </el-card>

    </div>
</template>



<!-- 行为 -->
<script>
    export default {

        // 数据处理
        data() {
            return {
                //获取用户列表的所需要设置的参数
                userInfor: {
                    page: 1, //用户列表页码数，默认每页5条数据
                },
                userTotal: 0,  //用户总数
                userPageSize: 5, //默认每页5条数据

                //用户列表---每页最多5条数据
                userList: [],

                // 控制修改用户信息对话框的显示与隐藏
                editUserDialogVisible: false,

                //获取到修改标签的数据对象
                editForm: {},

                // 修改标签名称表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                editFormRules: {
                    userNick: [
                        { required: true, message: '请输入用户昵称', trigger: 'blur' },
                        { min: 2, max: 15, message: '用户昵称长度在2到15个字符', trigger: 'blur' }
                    ],
                }
            }
        },


        //创建
        created() {
            this.getUserInformationList();
        },


        // 方法
        methods: {
            // 获取用户信息列表, async代表异步操作
            async getUserInformationList() {
                let userEmail = window.sessionStorage.getItem('userEmail');
                if (userEmail.length < 6) {
                    this.$message.error("请重新登录")
                    return;
                }
                const { data: res } = await this.$http.get('/user/list', {
                    params: {
                        page: this.userInfor.page,     //页数
                        userEmail: userEmail,          //邮箱
                        token: window.sessionStorage.getItem('token'),
                    }
                });
                if (res.status >= 400 && res.status <= 410) {//用户权限问题，需要登录
                    this.$router.push('/login');
                    return this.$message.error(res.msg);
                }else {
                    if(res.status != 200){
                        return this.$message.error(res.msg);
                    }
                }
                this.userList = res.userList;
                this.userTotal = res.totalNumber;
            },

            // 监听pageSize改变事件
            handleSizeChange(newPage) {
                console.log('handleSizeChange');
                this.userInfor.page = newPage;
                this.getUserInformationList();
            },

            // 监听当前页码改变
            handleCurrentChange(newPage) {
                this.userInfor.page = newPage;
                this.getUserInformationList();
            },

            // 判断是否超级管理员
            formatBooleanSuperuser: function (row, cellValue) {
                var ret = '' //你想在页面展示的值
                if (row.isSuperuser) {
                    ret = "是" //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },

            // 判断是否管理员，进入后台
            formatBooleanStaff: function (row, cellValue) {
                var ret = '' //你想在页面展示的值
                if (row.isStaff) {
                    ret = "是" //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },

            //点击编辑的修改用户信息弹窗
            showEditDialog(row) {
                console.log(JSON.parse(JSON.stringify(row)));
                this.editUserDialogVisible = true;
                this.editForm = JSON.parse(JSON.stringify(row));
            },

            //修改标签弹窗关闭
            editTagDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if(this.$refs.editFormRules !== undefined){
                    this.editFormRules.resetFields();
                }
            },

            //修改用户信息，提交
            editTagInfor() {
                this.$refs.editFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        console.log('预校验不通过');
                        return;
                    }
                    console.log(this.editForm.id);

                    //预校验通过,
                    const { data: res } = await this.$http.post('/user/edit', {
                        userId: this.editForm.userId,
                        userNick: this.editForm.userNick,
                        userLevel: this.editForm.userLevel,
                        userAddress: this.editForm.userAddress,
                        introduce: this.editForm.introduce,
                        token: window.sessionStorage.getItem('token'),
                    });
                    console.log(res.data);
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    this.$message.success('修改用户信息成功');

                    // 隐藏修改用户的对话
                    this.editUserDialogVisible = false;

                    //重新获取列表
                    this.getUserInformationList();
                })
            },


            //删除用户
            async removeUserInfor(userId) {
                // 询问是否真的删除当前
                const confirmResult = await this.$confirm('此操作将删除该标签名称, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch(err => {
                    return err;
                });

                // 如果标签名称，确认删除，则返回值为字符串 confirm 
                // 如果标签名称，取消删除，则返回值为字符串 cancel 
                console.log(confirmResult);
                if (confirmResult !== 'confirm') {
                    return this.$message.info('已取消删除操作');
                }
                //预校验通过,
                const { data: res } = await this.$http.post('/user/delete', {
                    userId: userId
                });
                console.log(res.data);
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('删除用户成功');

                //重新获取列表
                this.getUserInformationList();
            }
        },



    }
</script>





<!-- 样式 -->
<style lang="less" scoped>
    /*  使用deep样式穿透 面包屑导航里面字体样式 */
    .userListNavi /deep/ .el-breadcrumb {
        font-size: 20px !important;
    }
</style>