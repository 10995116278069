<template>
    <!-- 编辑文章---页面 -->
    <div>
        <!-- 顶部菜单栏操作 el-row配置行,el-col配置列-->
        <el-row :gutter="1" class="articleTabClass">
            <el-col>
                <!-- :span="12" -->
                <el-button type="primary" @click='goBackPage'>
                    返回
                </el-button>
                <el-button type="primary" @click='saveArticleInformation'>
                    保存
                </el-button>

                <el-select class="articleTypeClass" v-model="articleType" placeholder="请选择文章分类"
                    @change='newTypeFunction'>
                    <el-option key="999" value="新建">
                    </el-option>
                    <el-option v-for="item in articleTypeList" :key="item.typeId" :value="handleArticleType(item)">
                    </el-option>
                </el-select>

                <span class="articleGradeHeaderStyle">文章等级</span>
                <el-input class="articleGradeStyle" v-model="articleGrade" placeholder="等级"></el-input>

                <!-- multiple是否支持多选  @change:选中值发生变化时触发-->
                <el-select class="articleTagClass" v-model="articleTag" placeholder="请选择文章标签" :multiple="selectShow"
                    @change='newTagFunction'>
                    <el-option key="999" value="新建">
                    </el-option>

                    <template>
                        <div v-for="item in articleTagList" :key="item.tagId">
                            <el-option v-if="item.tagName.indexOf(filterString)>=0" :label="item.tagName"
                                :value="item.tagName"></el-option>
                        </div>
                    </template>
                </el-select>

                <el-input class="filterStringTypeStyle" v-model="filterString" placeholder="请输入过滤的字符串"></el-input>

            </el-col>
        </el-row>



        <!-- 添加文章分类的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
        <el-dialog title="添加分类" :visible.sync="addArticleTypeDialogVisible" width="50%"
            @close="addArticleTypeDialogClose">
            <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
            <el-form :model="addTypeForm" :rules="addTypeFormRules" ref="addTypeFormRef" label-width="100px">
                <!-- prop验证规则属性 v-model双向绑定数据-->
                <el-form-item label="父级" prop="parentName">
                    <el-select v-model="addTypeForm.parentName" placeholder="请选择父级">
                        <el-option label="顶级" value="顶级"></el-option>
                        <!-- 外层for循环，里面el-option采用v-if条件判断 -->
                        <template>
                            <div v-for="(item, index) in articleTypeParentList" :key="index">
                                <!-- 判断是否父级类 -->
                                <el-option v-if="item.grate == 1 " :label="item.parentName" :value="item.parentName">
                                </el-option>
                            </div>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="分类名称" prop="subName">
                    <el-input v-model="addTypeForm.subName"></el-input>
                </el-form-item>
                <el-form-item label="分类介绍" prop="content">
                    <el-input v-model="addTypeForm.content"></el-input>
                </el-form-item>
            </el-form>

            <!-- 底部区域 -->
            <div slot="footer" class="dialog-footer">
                <el-button @click="addArticleTypeDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addArticleType">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 添加文章标签的对话框 :visible.sync控制对话的显示与隐藏功能, close对话关闭触发事件-->
        <el-dialog title="添加标签" :visible.sync="addArticleTagDialogVisible" width="50%"
            @close="addArticleTagDialogClose">
            <!-- 内容主体区域 :model双向绑定表单数据，  :rules双向绑定验证规则  ref给当前组件起了引用名称，后面需要用到-->
            <el-form :model="addTagForm" :rules="addTagFormRules" ref="addTagFormRef" label-width="100px">
                <!-- prop验证规则属性 v-model双向绑定数据-->
                <el-form-item label="标签名称" prop="tagName">
                    <el-input v-model="addTagForm.tagName"></el-input>
                </el-form-item>
            </el-form>
            <!-- 底部区域 -->
            <div slot="footer">
                <el-button @click="addArticleTagDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addArticleTag">确 定</el-button>
            </div>
        </el-dialog>


        <!-- 卡片视图区域  -->
        <el-card class="articleViewClass">
            <el-row :gutter="24" class="articleTitleClass">
                <el-col :span="24">
                    <el-input v-model="articleTitle" placeholder="请输入文章标题"></el-input>
                </el-col>
            </el-row>

            <el-row :gutter="24" class="articleIntroductionStyle">
                <el-col :span="24">
                    <el-input v-model="articleIntroduction" placeholder="请输入文章简介"></el-input>
                </el-col>
            </el-row>


            <div class="articleContentStyle">
                <!-- 工具栏 -->
                <Toolbar class="toolBarClass" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />

                <!-- 编辑器 -->
                <Editor class="editorClass" v-model="html2" :defaultConfig="editorConfig" :mode="mode"
                    @onCreated="onCreated" @onChange="onChange" />
            </div>
        </el-card>

    </div>
</template>



<script>
    import '@wangeditor/editor/dist/css/style.css'
    import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
    import { SlateTransforms } from '@wangeditor/editor'

    export default {
        components: { Editor, Toolbar },
        // 数据处理
        data() {
            return {
                articleId: 0,           //文章ID
                articleTitle: '',       //文章标题
                articleIntroduction: '', //文章简介
                articleType: '',        //文章分类
                articleTag: [],         //文章标签，支持多个标签
                filterString: '',       //过滤的字符串
                articleGrade:0,         //文章等级，对应

                editor: null, //实例
                html2: '',  //html样式
                toolbarConfig: {},   //工具栏配置  head:'标题'
                editorConfig: { placeholder: '请输入内容...' }, //编译器配置
                mode: 'default',        // or 'simple'，  样式
                articleContent: '',//存储内容


                articleTypeParentList: [],  //文章一级分类列表，所有的一级分类
                // 控制添加文章分类对话框的显示与隐藏
                addArticleTypeDialogVisible: false,
                // 添加文章分类的表单数据对象
                addTypeForm: {
                    parentName: '', //父类
                    subName: '',    //分类名称---有可能是父类名称，也有可能是子类名称
                    content: '请填写介绍',
                },

                // 添加文章分类表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addTypeFormRules: {
                    parentName: [
                        { required: true, message: '请选择父级', trigger: 'blur' }
                    ],
                    subName: [
                        { required: true, message: '请输入分类名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '分类名称长度在2到15个字符', trigger: 'blur' }
                    ],
                },

                articleTypeList: [],    //文章分类列表
                articleTagList: [],     //文章标签列表
                selectShow: true,        //是否多选
                // 控制添加文章标签对话框的显示与隐藏
                addArticleTagDialogVisible: false,
                //添加文章标签的表单数据对象
                addTagForm: {
                    tagName: '',    //标签名称
                },

                // 添加文章标签表单数据的验证规则对象  trigger:blur对应必填项，change为选填项
                addTagFormRules: {
                    tagName: [
                        { required: true, message: '请输入标签名称', trigger: 'blur' },
                        { min: 2, max: 15, message: '标签名称长度在2到15个字符', trigger: 'blur' }
                    ],
                },

            }
        },

        beforeDestroy() {
            const editor = this.editor;
            if (editor == null) {
                return
            }
            // 【注意】组件销毁时，及时销毁编辑器
            editor.destroy();
        },

        //创建
        created() {
            this.articleId = window.sessionStorage.getItem('articleId')
            this.articleTitle = window.sessionStorage.getItem('articleTitle')
            this.articleIntroduction = window.sessionStorage.getItem('articleIntroduction')
            this.articleType = window.sessionStorage.getItem('articleTypeDetail')
            this.articleTag = window.sessionStorage.getItem('articleTag').split(',');
            this.articleGrade = window.sessionStorage.getItem('articleGrade')
            console.log(this.articleTag);
            this.getArticleTypeList();
            this.getArticleTagList();
        },

        //mounted是一个声明周期钩子函数，页加载渲染完成
        mounted() {
            // 定时延时执行
            setTimeout(() => {
                // json数据格式
                // let contentNew = JSON.parse(window.sessionStorage.getItem('articleContent'))
                // SlateTransforms.insertNodes(this.editor, contentNew);

                this.html2 = window.sessionStorage.getItem('articleContent');
            }, 500)
        },

        // 方法
        methods: {
            // 返回上一页
            goBackPage() {
                this.$router.go(-1);
            },

            // 获取文章分类列表, async代表异步操作
            async getArticleTypeList() {
                const { data: res } = await this.$http.get('/article/type/list', {
                    params: {
                        page: 999, //文章分类page传入999，代表获取全部文章标题列表
                    }
                });
                this.articleTypeList = res.typeList;
                this.articleTypeParentList = res.typeParentList;
            },

            // 获取文章标签列表, async代表异步操作
            async getArticleTagList() {
                const { data: res } = await this.$http.get('/article/tag/list', {
                    params: {
                        page: 999, //文章标签page传入999，代表获取全部文章标签列表
                    }
                });
                this.articleTagList = res.tagList;
            },

            // 处理文章分类相关数据
            handleArticleType: function (item) {
                return item.parentName + "/" + item.subName;
            },

            // 新建文章分类功能功能
            newTypeFunction() {
                console.log(this.articleType);
                if (this.articleType == '新建') {
                    this.articleType = '';
                    this.addArticleTypeDialogVisible = true;
                }
            },

            // 监听添加文章分类对话关闭事件
            addArticleTypeDialogClose() {
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addTypeFormRef !== undefined) {
                    this.addTypeFormRef.resetFields();
                }
            },

            // 点击确定按钮，添加文章分类信息
            addArticleType() {
                this.$refs.addTypeFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/type/add', {
                        parentName: this.addTypeForm.parentName,
                        subName: this.addTypeForm.subName,
                        content: this.addTypeForm.content,
                        token: window.sessionStorage.getItem('token'),
                    });
                    if (res.status !== 200) {
                        return this.$message.error('新建文章分类失败');
                    }
                    this.$message.success('新建文章分类成功');
                    this.addArticleTypeDialogVisible = false;  // 隐藏添加的对话
                    this.getArticleTypeList();          //重新获取平台列表
                })
            },

            // 新建标签功能
            newTagFunction() {
                console.log(this.articleTag)
                console.log(this.articleTag[this.articleTag.length - 1])
                if (this.articleTag[this.articleTag.length - 1] == '新建') {
                    this.articleTag.pop();
                    this.selectShow = false;
                    // 隐藏添加的对话
                    this.addArticleTagDialogVisible = true;
                }
            },

            // 监听添加标签对话关闭事件
            addArticleTagDialogClose() {
                this.selectShow = true;
                // undefined 表示一个变量未定义或未赋值的初始状态值
                if (this.$refs.addTagFormRef !== undefined) {
                    this.addTagFormRef.resetFields();
                }
            },

            // 点击确定按钮，添加标签信息
            addArticleTag() {
                this.selectShow = true;
                this.$refs.addTagFormRef.validate(async valid => {
                    if (!valid) {//预校验不通过
                        return;
                    }
                    console.log('数据请求');
                    //预校验通过,
                    const { data: res } = await this.$http.post('/article/tag/add', {
                        tagName: this.addTagForm.tagName,
                        token: window.sessionStorage.getItem('token'),
                    });
                    console.log(res);
                    if (res.status !== 200) {
                        return this.$message.error(res.msg);
                    }
                    this.$message.success('新建标签名称成功');

                    // 隐藏添加的对话
                    this.addArticleTagDialogVisible = false;

                    // 重新获取标签列表
                    this.getArticleTagList();
                })
            },

            // 保存文章编辑信息
            async saveArticleInformation() {
                console.log('保存文章信息-----');
                if (this.articleType == '') {
                    this.$message.error("请选择文章类型")
                    return;
                }
                var articleTypeId = 0;  //文章分类对应的ID
                for (let index = 0; index < this.articleTypeList.length; index++) {
                    const tempType = this.articleTypeList[index];
                    if (this.articleType == this.handleArticleType(tempType)) {
                        articleTypeId = tempType.typeId;
                        break;
                    }
                }

                if (this.articleTag.length == 0) {
                    this.$message.error("请选择文章标签")
                    return;
                }
                var articleTagId = "";  //文章标签对应的ID,多标签Id以;进行分割区分
                for (let index = 0; index < this.articleTag.length; index++) {
                    const tempTag = this.articleTag[index];
                    for (let indexList = 0; indexList < this.articleTagList.length; indexList++) {
                        const tempListTag = this.articleTagList[indexList];
                        if (tempTag == tempListTag.tagName) {
                            if (articleTagId == "") {
                                articleTagId = tempListTag.tagId
                            } else {
                                articleTagId = articleTagId + ";" + tempListTag.tagId
                            }
                            break;
                        }
                    }
                }

                if (this.articleTitle == '') {
                    this.$message.error("请输入文章标题")
                    return;
                }
                if (this.articleIntroduction == '') {
                    this.$message.error("请输入文章简介")
                    return;
                }
                // 预校验通过,编辑文章
                const { data: res } = await this.$http.post('/article/article/edit', {
                    articleId: this.articleId,       //文章ID
                    title: this.articleTitle,        //文章标题
                    introduction: this.articleIntroduction,  //文章简介
                    content: this.articleContent,   //文章内容
                    articleTypeId: articleTypeId,   //文章类型ID
                    articleTagId: articleTagId,     //文章标签，可能有多个标签，多标签Id以;进行分割区分
                    articleGrade: this.articleGrade,   //文章等级
                    token: window.sessionStorage.getItem('token'),
                });
                console.log(res.data);
                if (res.status !== 200) {
                    return this.$message.error(res.msg);
                }
                this.$message.success('修改文章成功');
            },

            onCreated(editor) {
                this.editor = Object.seal(editor);  // 一定要用 Object.seal() ，否则会报错
            },

            onChange(editor) {
                // JSON.stringify目的是将对象转换为字符串，与JSON.parse是一对
                // this.articleContent = JSON.stringify(editor.children)

                this.articleContent = this.editor.getHtml();
            },

            customAlert(info, type) {
                window.alert(`customAlert in Vue demo\n${type}:\n${info}`)
            },

            customPaste(editor, event, callback) {
                callback(true) // 返回 true ，继续默认的粘贴行为
            },


        },

    }
</script>







<!-- 样式 -->
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
    /* 顶部菜单栏操作样式 */
    .articleTabClass {
        padding-bottom: 10px;
    }

    /* 文章分类的样式 */
    .articleTypeClass {
        padding-left: 10px;
        width: 150px;
    }

    /* 文章标签的样式 */
    .articleTagClass {
        padding-left: 10px;
        width: 400px;
    }

     /* 文章等级的样式1 */
    .articleGradeHeaderStyle {
        margin-left: 10px;
        width: 80px;
        font-size: 16px;
    }

     /* 文章等级的样式2*/
    .articleGradeStyle {
        margin-left: 5px;
        width: 60px;
    }

    /* 模糊过滤的样式 */
    .filterStringTypeStyle {
        margin-left: 10px;
        width: 180px;
    }

    /* 文章标题的样式 */
    .articleViewClass {
        padding-bottom: 10px;
    }

    .articleTitleClass {
        padding-bottom: 10px;
    }

    /* 文章简介 */
    .articleIntroductionStyle {
        height: 40px;
        padding-bottom: 10px;
    }

    /* 文章正文 */
    .articleContentStyle {
        border: 1px solid #ccc;
        margin-top: 10px;
    }

    /* 工具栏 */
    .toolBarClass {
        border-bottom: 1px solid #ccc;
        height: 100px;
    }

    /* 编辑器的样式 */
    .editorClass {
        height: 400px;
    }
</style>